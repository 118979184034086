<template>
  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Thresholds
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'threshold-add' }" title="Add New Threshold"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Thresholds List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th style="width:10px !important;">Threshold ID</th>
            <th style="width:10px !important;">Category</th>
            <th style="width:10px !important;">Scope</th>
            <th style="width:10px !important;">Source</th>
            <th style="width:10px !important;">Metric</th>
            <th style="width:10px !important;">Filter</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!activeThresholds || activeThresholds.length === 0">
            <td class="subtle-info">
              No Thresholds Listed
            </td>
          </tr>
          <tr v-for="(threshold,i) of activeThresholds" :key="threshold.id" v-bind:class="{'data-row-last': i+1 == activeThresholds.length}">
            <td>
              <router-link :to="{  name: 'threshold-details', params: { id: threshold.id } }"
                v-bind:class="{ 'inactive': threshold.enabled === true }"
              >
                {{ threshold.id }}
              </router-link>
            </td>
            <td style="padding-right:15px">
              {{ threshold.category }}
            </td>
            <td style="padding-right:15px">
              {{ threshold.scope }}
            </td>
            <td style="padding-right:15px">
              {{ threshold.source }}
            </td>
            <td style="padding-right:15px">
              {{ threshold.metric }}
            </td>
            <td style="padding-right:15px">
              {{ threshold.filter }}
            </td>
            <td>
              {{ threshold.notes }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseThresholdComponent from '@/components/notifications/BaseThresholdComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'thresholds-recent-list',
  extends: BaseThresholdComponent,
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveAllThresholds(this.$refs.spinner.stopSpinning)
    }
  },
  mounted () {
    this.refreshList()
    this.setNavSub('thresholds')
  },
  components: { Spinner, AddIcon }
}
</script>
