<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
        <router-link :to="{  path: '/alerts' }" class="nav-link" v-bind:class="{'active': activeTab === 'alerts'}">Alerts</router-link>
    </li>
    <li class="nav-item">
        <router-link :to="{  name: 'threshold-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'thresholds'}">Thresholds</router-link>
    </li>
    <li class="nav-item">
        <router-link :to="{  name: 'template-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'templates'}">Templates</router-link>
    </li>
    <li class="nav-item d-sm-none">
        <router-link :to="{  name: 'subscription-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'subscriptions'}">Subs</router-link>
    </li>
    <li class="nav-item d-none d-sm-inline">
        <router-link :to="{  name: 'subscription-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'subscriptions'}">Subscriptions</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  data () {
    return {
      activeTab: this.activeTabName
    }
  },
  props: ['activeTabName']
}
</script>
