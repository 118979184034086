<template>
  <TabBar activeTabName="devices" />
  <div class="row justify-content-center abutting">
    <RecentDeviceList />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomerMainList from '@/components/fleet/CustomerMainList.vue' // @ is an alias to /src
import RanchMainList from '@/components/fleet/RanchMainList.vue' // @ is an alias to /src
import RecentDeviceList from '@/components/fleet/RecentDeviceList.vue' // @ is an alias to /src
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    CustomerMainList,
    RanchMainList,
    RecentDeviceList,
    TabBar
  }
})
export default class FleetHome extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Fleet', '')
  }
}
</script>
