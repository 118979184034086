<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="manufacturer" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': manufacturer.status === 'inactive'}">Manufacturer</span> {{ manufacturer.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="manufacturer.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </div>

        <div class="row align-content-center" v-if="manufacturer.description">
          <div class="col">
            <span class="list-heading-label">
              {{ manufacturer.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="manufacturer.notes">
          <div class="col node-notes node-general-notes">
            {{ manufacturer.notes }}
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="manufacturer.models">
          <div class="col node-general-header">
            Models:
          </div>
          <div class="col-auto">
            {{ manufacturer.models }}
          </div>
        </div>

      </div>

    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select v-model="manufacturer.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" id="cascade"/>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="manufacturer.description"
              name="description"
              style="width:240px;"
              placeholder="Proper Name, Website, etc."
              maxlength="48"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="manufacturer.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Manufacturer that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="models">Models</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="models"
              v-model="manufacturer.models"
              name="models"
              style="width:240px;"
              placeholder="comma,separated,model,list"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(manufacturer.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseManufacturerComponent from '@/components/fleet/BaseManufacturerComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'

export default {
  name: 'manufacturer-info',
  extends: BaseManufacturerComponent,
  data () {
    return {
      manufacturerBeforeEdit: {}
    }
  },
  methods: {
    getManufacturer (id) {
      FleetDataService.getManufacturer(id)
        .then(response => {
          this.manufacturer = response.data
          this.manufacturerBeforeEdit = Object.assign({}, this.manufacturer)
          this.$state.creationName = this.manufacturer.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Manufacturer')
        })
    },
    handleSubmit () {
      FleetDataService.updateManufacturer(this.manufacturer.id, this.manufacturer)
        .then(response => {
          this.isError = false
          this.message = 'Manufacturer Updated'
          this.setNavManufacturer(this.$route.params.id)
          this.getManufacturer(this.manufacturer.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Manufacturer Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.manufacturer, this.manufacturerBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getManufacturer(this.$route.params.id)
    this.setNavManufacturer(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
  },
  components: { CloseIcon, EditIcon, GeneralStatusOptions }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
