import http from '@/http-common'
import qs from 'qs'

class CustomerDataService {
  getAll () {
    return http.get('/customers')
  }

  get (id: string) {
    return http.get(`/customers/${id}`)
  }

  create (data: any) {
    return http.post('/customers', qs.stringify(data))
  }

  update (id: string, data: any) {
    return http.put(`/customers/${id}`, qs.stringify(data))
  }
}

export default new CustomerDataService()
