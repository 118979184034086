<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'

export default {
  name: 'base-network-server-component',
  extends: BaseFleetComponent,
  methods: {
  }
}
</script>
