<template>
  <TabBar activeTabName="customers" />
  <div class="row justify-content-center abutting">
    <CustomerInfo />
    <div class="col-auto d-xl"></div>
    <div class="col-auto abutting">
      <RanchesByCustomer />
      <AlertsByN entityType="Customer" class="justify-self-start padding-top-15" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomerInfo from '@/components/fleet/CustomerInfo.vue' // @ is an alias to /src
import RanchesByCustomer from '@/components/fleet/RanchesByCustomer.vue' // @ is an alias to /src
import AlertsByN from '@/components/fleet/AlertsByN.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    CustomerInfo,
    RanchesByCustomer,
    AlertsByN,
    TabBar
  }
})
export default class CustomerDetails extends Vue {}
</script>
