<template>
  <TabBar activeTabName="ranches" />
  <div class="row justify-content-center abutting">
    <RanchInfo />
    <div class="col-auto d-xl"></div>
    <div class="col-auto abutting">
      <DevicesByRanchList />
      <GatewaysByRanchList class="padding-top-10" />
      <AlertsByN entityType="Ranch" class="justify-self-start padding-top-15" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import RanchInfo from '@/components/fleet/RanchInfo.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'
import DevicesByRanchList from '@/components/fleet/DevicesByRanchList.vue'
import GatewaysByRanchList from '@/components/fleet/GatewaysByRanchList.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    RanchInfo,
    AlertsByN,
    DevicesByRanchList,
    GatewaysByRanchList,
    TabBar
  }
})
export default class RanchDetails extends Vue {}
</script>
