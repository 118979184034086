<template>

  <div class="container justify-content-center big-menu-list">

    <h4>Select a Space to Manage</h4>
    <ul class="list-group">

      <li class="list-group-item">
        <router-link :to="{  name: 'fleet-home'}" style="font-size:20px;font-weight:bold;">
          Fleet
        </router-link>
        <p>Device Inventory &amp; Deployment Topology</p>
      </li>

      <li class="list-group-item">
        <router-link :to="{  name: 'notifications-home'}" style="font-size:20px;font-weight:bold;">
          Notifications
        </router-link>
        <p>Threshold-based Customer Alerts</p>
      </li>

      <li class="list-group-item">
        <router-link :to="{  name: 'admin-home'}" style="font-size:20px;font-weight:bold;">
          System Administration
        </router-link>
        <p>Permissions, Backend Metadata, etc. </p>
      </li>

    </ul>
  </div>

<!--
  <hr />

  <router-link :to="{  name: 'notifications-home'}">
    Notifications Management
  </router-link>

  <hr />

  <router-link :to="{  name: 'admin-home'}">
    System Administration
  </router-link>
-->
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '../services/NavigationService'

@Options({})
export default class Home extends Vue {
  mounted () {
    NavigationService.setNavHome()
  }
}
</script>
