<template>

  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        All Gateways
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'gateway-add' }" title="Add New Gateway"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Gateways List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-5"/>
      </div>
    </div>

    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th>Gateway ID</th>
            <th>Ranch</th>
            <th class="d-none d-md-table-cell">Manufacturer</th>
            <th class="d-none d-md-table-cell">Network</th>
            <th class="d-none d-md-table-cell">Connectivity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!activeGateways || activeGateways.length === 0">
            <td class="subtle-info">
              No Gateways Listed
            </td>
          </tr>
          <tr v-for="(gateway,i) of activeGateways" :key="gateway.id" v-bind:class="{'data-row-last': i+1 == activeGateways.length}">
            <td>
              <router-link :to="{  name: 'gateway-details', params: { id: gateway.id } }"
                v-bind:class="{ 'inactive': gateway.status === 'inactive' }"
              >
                {{ gateway.id }}
              </router-link>
            </td>
            <td style="padding-right: 15px;">
              {{ gateway.ranch }}
            </td>
            <td class="d-none d-md-table-cell">
              {{ gateway.manufacturer }}
            </td>
            <td class="d-none d-md-table-cell">
              {{ gateway.networkServer }}
            </td>
            <td class="d-none d-md-table-cell">
              {{ gateway.networkAccess }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import BaseGatewayComponent from '@/components/fleet/BaseGatewayComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'gateways-list',
  extends: BaseGatewayComponent,
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveAllGateways(this.$refs.spinner.stopSpinning)
    }
  },
  mounted () {
    this.refreshList()
  },
  components: { Spinner, AddIcon }
}
</script>
