import axios from 'axios'
import auth from './app/auth'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(function (config) {
  if (!config.url?.endsWith('/userInfo')) {
    config.headers.Authorization = 'Bearer ' + auth.auth.getSignInUserSession().getIdToken().jwtToken
  }
  return Promise.resolve(config)
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default instance
