class NavigationService {
  setNavHome () {
    const l1i = document.getElementById('l1-li')
    const l4i = document.getElementById('l4-li')
    const l3i = document.getElementById('l3-li')
    const l2i = document.getElementById('l2-li')

    const l1s = document.getElementById('l1-s')
    const l4s = document.getElementById('l4-s')
    const l3s = document.getElementById('l3-s')
    const l2s = document.getElementById('l2-s')

    const l1a = document.getElementById('l1-a')
    const l4a = document.getElementById('l4-a')
    const l3a = document.getElementById('l3-a')
    const l2a = document.getElementById('l2-a')

    if (l4i) {
      l4i.style.display = 'none'
    }
    if (l1i) {
      l1i.style.display = 'none'
    }
    if (l3i) {
      l3i.style.display = 'none'
    }
    if (l2i) {
      l2i.style.display = 'none'
    }

    if (l4a) {
      l4a.style.display = 'none'
    }
    if (l1a) {
      l1a.style.display = 'none'
    }
    if (l3a) {
      l3a.style.display = 'none'
    }
    if (l2a) {
      l2a.style.display = 'none'
    }

    if (l4s) {
      l4s.style.display = 'none'
    }
    if (l1s) {
      l1s.style.display = 'none'
    }
    if (l3s) {
      l3s.style.display = 'none'
    }
    if (l2s) {
      l2s.style.display = 'none'
    }
  }

  updateNavElement (l:HTMLElement | null, s:HTMLElement | null, a:HTMLElement | null, t:string, u:string) {
    if (!t || t === '') {
      if (l) {
        l.style.display = 'none'
      }
      if (s) {
        s.style.display = 'none'
      }
      if (a) {
        a.style.display = 'none'
      }
      return
    }
    if (l) {
      l.style.display = 'inline'
    }
    if (s) {
      s.innerText = ''
      s.style.display = 'inline'
    }
    if (u) {
      if (s) {
        s.innerText = ''
      }
      if (a) {
        a.innerText = t
        a.setAttribute('href', u)
        a.style.display = 'inline'
      }
    } else {
      if (a) {
        a.style.display = 'none'
      }
      if (s) {
        s.innerText = t
      }
    }
  }

  setNavLevel1 (l1:string, u1:string) {
    const l1i = document.getElementById('l1-li')
    const l1s = document.getElementById('l1-s')
    const l1a = document.getElementById('l1-a')

    const l2i = document.getElementById('l2-li')
    const l2s = document.getElementById('l2-s')
    const l2a = document.getElementById('l2-a')

    const l3i = document.getElementById('l3-li')
    const l3s = document.getElementById('l3-s')
    const l3a = document.getElementById('l3-a')

    const l4i = document.getElementById('l4-li')
    const l4a = document.getElementById('l4-a')
    const l4s = document.getElementById('l4-s')

    this.updateNavElement(l1i, l1s, l1a, l1, '')
    this.updateNavElement(l2i, l2s, l2a, '', '')
    this.updateNavElement(l3i, l3s, l3a, '', '')
    this.updateNavElement(l4i, l4s, l4a, '', '')
  }

  setNavLevel2 (l1:string, l2:string, u1:string, u2:string) {
    const l1i = document.getElementById('l1-li')
    const l1s = document.getElementById('l1-s')
    const l1a = document.getElementById('l1-a')

    const l2i = document.getElementById('l2-li')
    const l2s = document.getElementById('l2-s')
    const l2a = document.getElementById('l2-a')

    const l3i = document.getElementById('l3-li')
    const l3s = document.getElementById('l3-s')
    const l3a = document.getElementById('l3-a')

    const l4i = document.getElementById('l4-li')
    const l4a = document.getElementById('l4-a')
    const l4s = document.getElementById('l4-s')

    this.updateNavElement(l1i, l1s, l1a, l1, '')
    this.updateNavElement(l2i, l2s, l2a, l2, u2)
    this.updateNavElement(l3i, l3s, l3a, '', '')
    this.updateNavElement(l4i, l4s, l4a, '', '')
  }

  setNavLevel3 (l1:string, l2:string, l3:string, u1:string, u2:string, u3:string) {
    const l1i = document.getElementById('l1-li')
    const l1s = document.getElementById('l1-s')
    const l1a = document.getElementById('l1-a')

    const l2i = document.getElementById('l2-li')
    const l2s = document.getElementById('l2-s')
    const l2a = document.getElementById('l2-a')

    const l3i = document.getElementById('l3-li')
    const l3s = document.getElementById('l3-s')
    const l3a = document.getElementById('l3-a')

    const l4i = document.getElementById('l4-li')
    const l4a = document.getElementById('l4-a')
    const l4s = document.getElementById('l4-s')

    this.updateNavElement(l1i, l1s, l1a, l1, '')
    this.updateNavElement(l2i, l2s, l2a, l2, u2)
    this.updateNavElement(l3i, l3s, l3a, l3, u3)
    this.updateNavElement(l4i, l4s, l4a, '', '')
  }

  setNavLevel4 (l1:string, l2:string, l3:string, l4:string, u1:string, u2:string, u3:string, u4:string) {
    const l1i = document.getElementById('l1-li')
    const l1s = document.getElementById('l1-s')

    const l2i = document.getElementById('l2-li')
    const l2s = document.getElementById('l2-s')
    const l2a = document.getElementById('l2-a')

    const l3i = document.getElementById('l3-li')
    const l3s = document.getElementById('l3-s')
    const l3a = document.getElementById('l3-a')

    const l4i = document.getElementById('l4-li')
    const l4a = document.getElementById('l4-a')
    const l4s = document.getElementById('l4-s')

    this.updateNavElement(l1i, l1s, null, l1, '')
    this.updateNavElement(l2i, l2s, l2a, l2, u2)
    this.updateNavElement(l3i, l3s, l3a, l3, u3)
    this.updateNavElement(l4i, l4s, l4a, l4, u4)
  }
}

export default new NavigationService()
