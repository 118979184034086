import http from '@/http-common'
import qs from 'qs'

class NotificationsDataService {
  // Alerts CRUD
  getRecentAlerts (filterBy: string, filterFor: string) {
    let query = ''
    if (filterBy !== '' && filterFor !== '') {
      query = '?filterBy=' + filterBy + '&filterFor=' + filterFor
    }
    return http.get('/alerts/recent' + query)
  }

  getAlert (id: string) {
    return http.get(`/alerts/${id}`)
  }

  updateAlert (id: string, data: any) {
    return http.put(`/alerts/${id}`, qs.stringify(data))
  }

  resolveAlert (id: string, data: any) {
    return http.put(`/alerts/${id}/resolve`, qs.stringify(data))
  }

  lockAlert (id: string, data: any) {
    return http.put(`/alerts/${id}/lock`, qs.stringify(data))
  }

  unlockAlert (id: string, data: any) {
    return http.put(`/alerts/${id}/unlock`, qs.stringify(data))
  }

  // Thresholds CRUD
  getAllThresholds () {
    return http.get('/thresholds')
  }

  getThreshold (id: string) {
    return http.get(`/thresholds/${id}`)
  }

  createThreshold (data: any) {
    return http.post('/thresholds', qs.stringify(data))
  }

  updateThreshold (id: string, data: any) {
    return http.put(`/thresholds/${id}`, qs.stringify(data))
  }

  // Templates CRUD
  getAllTemplates () {
    return http.get('/templates')
  }

  getTemplate (id: string) {
    return http.get(`/templates/${id}`)
  }

  createTemplate (data: any) {
    return http.post('/templates', qs.stringify(data))
  }

  updateTemplate (id: string, data: any) {
    return http.put(`/templates/${id}`, qs.stringify(data))
  }

  // Subscriptions Admininstration
  getApnSubscriptionsByFilter (filterBy: string, filterOn: string) {
    return http.get('/subscriptions/apn/filtered?' + qs.stringify({ filterBy: filterBy, filterOn: filterOn }))
  }

  getApnSubscriptionsByDeviceToken (deviceToken: string, bundleID: string) {
    return http.get(`/subscriptions/apn/token/${deviceToken}/${bundleID}`)
  }

  deleteApnSubscriptionsByDeviceToken (deviceToken: string, bundleID: string) {
    return http.delete(`/subscriptions/apn/token/${deviceToken}/${bundleID}`)
  }

  sendTestNotification (data: any) {
    return http.post('/subscriptions/apn/send', qs.stringify(data))
  }
}

export default new NotificationsDataService()
