<template>

  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        All Manufacturers
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'manufacturer-add' }" title="Add New Manufacturer"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Manufacturers List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <ul class="list-group">
      <li v-if="!activeManufacturers || activeManufacturers.length === 0" class="list-group-item subtle-info">
        No Manufacturers Listed
      </li>
      <li class="list-group-item" v-for="manufacturer in activeManufacturers" :key="manufacturer.id">
        <router-link :to="{  name: 'manufacturer-details', params: { id: manufacturer.id } }"
          v-bind:class="{ 'inactive': manufacturer.status === 'inactive' }"
        >
          {{ manufacturer.id }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseManufacturerComponent from '@/components/fleet/BaseManufacturerComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'manufacturers-list',
  extends: BaseManufacturerComponent,
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveAllManufacturers(this.$refs.spinner.stopSpinning)
    }
  },
  mounted () {
    this.refreshList()
  },
  components: { Spinner, AddIcon }
}
</script>
