<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="agtemplate" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label">Template</span> {{ agtemplate.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="agtemplate.description">
          <div class="col">
            <span class="list-heading-label">
              {{ agtemplate.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="agtemplate.notes">
          <div class="col node-notes node-general-notes">
            {{ agtemplate.notes }}
          </div>
        </div>

        <div class="row align-content-center" v-if="agtemplate.medium">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Medium:</span> {{ agtemplate.medium }}
          </div>
        </div>

        <div class="row align-content-center" v-if="agtemplate.subject">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Subject:</span> {{ agtemplate.subject }}
          </div>
        </div>

        <div class="row align-content-center no-border-bottom no-padding-bottom" v-if="agtemplate.body">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Body:</span>
          </div>
        </div>

        <div class="row align-content-center no-padding-top" v-if="agtemplate.body">
          <div class="col-auto contact-row-item" >
            {{ agtemplate.body }}
          </div>
        </div>

      </div>
    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="agtemplate.description"
              name="description"
              style="width:240px;"
              placeholder="Short &amp; Human-Readable"
              maxlength="72"
            />
          </div>
        </div>

        <div id="v-model-mediums" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="mediums">Medium</label>
          <div class="col-auto form-right-input">
            <select v-model="agtemplate.medium" id="mediums" style="width: 240px" required>
              <NotificationMediums />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="subject">Subj</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="subject"
              v-model="agtemplate.subject"
              name="subject"
              style="width:240px;"
              placeholder="Notification Subject"
              maxlength="255"
            />
          </div>
        </div>

        <div class="row form-group">
          <div style="max-width:280px;">
            <label class="col-form-label label-required" for="body">Body</label>
          </div>
          <div>
            <textarea
              id="body"
              v-model="agtemplate.body"
              name="body"
              class="notes-box width-330 height-200"
              placeholder="Enter the body of Notification Template"
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header width-55" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="agtemplate.notes"
              name="notes"
              class="notes-box width-270"
              placeholder="Enter any info about this Template that Agrology may find useful in the future."
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(agtemplate.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseTemplateComponent from '@/components/notifications/BaseTemplateComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import NotificationMediums from '@/components/selectOptions/NotificationMediums.vue'

export default {
  name: 'agtemplate',
  extends: BaseTemplateComponent,
  data () {
    return {
      templateBeforeEdit: {}
    }
  },
  methods: {
    getTemplate (id) {
      NotificationsDataService.getTemplate(id)
        .then(response => {
          this.agtemplate = response.data
          this.templateBeforeEdit = Object.assign({}, this.agtemplate)
          this.$state.creationName = this.agtemplate.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Template')
        })
    },
    handleSubmit () {
      NotificationsDataService.updateTemplate(this.agtemplate.id, this.agtemplate)
        .then(response => {
          this.isError = false
          this.message = 'Template Updated'
          this.setNavTemplate(this.$route.params.id)
          this.getTemplate(this.agtemplate.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Template Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.agtemplate, this.templateBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getTemplate(this.$route.params.id)
    this.setNavTemplate(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
  },
  components: { CloseIcon, EditIcon, NotificationMediums }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
