<script>
import BaseComponent from '@/components/BaseComponent.vue'
import RanchDataService from '@/services/fleet/RanchDataService'
import CustomerDataService from '@/services/fleet/CustomerDataService'
import FleetDataService from '@/services/fleet/FleetDataService'
import NavigationService from '@/services/NavigationService'

export default {
  name: 'base-fleet-component',
  extends: BaseComponent,
  data () {
    return {
      customer: null,
      ranch: null,
      device: null,
      customers: [],
      ranches: [],
      devices: [],
      networkServers: [],
      networkServer: null,
      networkServerName: '',
      manufacturers: [],
      manufacturer: null,
      manufacturerName: '',
      gateways: [],
      gateway: null,
      gatewayName: '',
      ranchName: '',
      customerName: '',
      deviceID: ''
    }
  },
  methods: {
    customerFromRanch () {
      if (this.device) {
        for (const ranch of this.ranches) {
          if (ranch.id === this.device.ranch) {
            this.device.customer = ranch.customer
            return
          }
        }
      }
    },
    retrieveAllRanches (f) {
      RanchDataService.getAll()
        .then(response => {
          this.ranches = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllCustomers (f) {
      CustomerDataService.getAll()
        .then(response => {
          this.customers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllNetworkServers (f) {
      FleetDataService.getAllNetworkServers()
        .then(response => {
          this.networkServers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllManufacturers (f) {
      FleetDataService.getAllManufacturers()
        .then(response => {
          this.manufacturers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllGateways (f) {
      FleetDataService.getAllGateways()
        .then(response => {
          this.gateways = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    setNavCustomer (custLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel2('Fleet', custLabel, '#/fleet')
    },
    setNavRanch (custLabel, ranchLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', custLabel, ranchLabel, '#/fleet', '#/customers/' + custLabel)
    },
    setNavNetworkServer (serverLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', serverLabel, '', '#/fleet')
    },
    setNavManufacturer (manLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', manLabel, '', '#/fleet')
    },
    setNavGateway (gwLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', gwLabel, '', '#/fleet')
    },
    hypenateDeviceId (id) {
      var out = ''
      if (id && id.length > 0) {
        // Change all letters to upper case
        out = id.toUpperCase()
        // Remove all but alphanumeric characters
        out = out.replace(/\W/ig, '')
        // Append a hyphen after every two characters
        out = out.replace(/(.{2})/g, '$1-')
        // remove any trailing hyphen
        if (out.slice(-1) === '-') {
          out = out.substring(0, out.length - 1)
        }
      }
      return out
    }
  },
  computed: {
    activeRanches () {
      if (this.$state.showInactiveNodes) {
        return this.ranches
      }
      var active = []
      for (const ranch of this.ranches) {
        if (ranch.status === 'inactive') {
          continue
        }
        active.push(ranch)
      }
      return active
    },
    activeDevices () {
      if (this.$state.showInactiveNodes) {
        return this.devices
      }
      var active = []
      for (const device of this.devices) {
        if (device.status === 'inactive') {
          continue
        }
        active.push(device)
      }
      return active
    },
    activeCustomers () {
      if (this.$state.showInactiveNodes) {
        return this.customers
      }
      var active = []
      for (const customer of this.customers) {
        if (customer.status === 'inactive') {
          continue
        }
        active.push(customer)
      }
      return active
    },
    activeNetworkServers () {
      if (this.$state.showInactiveNodes) {
        return this.networkServers
      }
      var active = []
      for (const networkServer of this.networkServers) {
        if (networkServer.status === 'inactive') {
          continue
        }
        active.push(networkServer)
      }
      return active
    },
    activeManufacturers () {
      if (this.$state.showInactiveNodes) {
        return this.manufacturers
      }
      var active = []
      for (const manufacturer of this.manufacturers) {
        if (manufacturer.status === 'inactive') {
          continue
        }
        active.push(manufacturer)
      }
      return active
    },
    activeGateways () {
      if (this.$state.showInactiveNodes) {
        return this.gateways
      }
      var active = []
      for (const gateway of this.gateways) {
        if (gateway.status === 'inactive') {
          continue
        }
        active.push(gateway)
      }
      return active
    }
  }
}
</script>

<style>
</style>
