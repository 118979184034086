import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import { VueCookieNext } from 'vue-cookie-next'
import auth from './app/auth'
import UserInfoApi from './app/user-info-api'

const state = reactive({
  isSignedIn: false,
  email: '',
  userInfo: null,
  showInactiveNodes: false,
  crudMessage: '',
  creationName: '',
  currentSpace: ''
})

const fleetPathPrefixes = ['fleet', 'device', 'customer', 'ranch', 'manufacturer', 'network', 'gateway']

router.beforeResolve(async (to, from, next) => {
  if (!to || !to.path) {
    next()
    return
  }

  if (to.path.indexOf('id_token=') > 0 || to.path.indexOf('access_token=') > 0) {
    auth.auth.parseCognitoWebResponse(window.location.href.replace('#/', '#'))
    next({
      name: 'Home'
    })
    return
  }

  if (!auth.auth.isUserSignedIn()) {
    state.isSignedIn = false
    auth.auth.getSession()
  } else {
    state.isSignedIn = true
    if (!state.userInfo) {
      UserInfoApi.getUserInfo().then(response => {
        if (response.email) {
          state.email = response.email
          state.userInfo = response
        }
      })
    }
  }

  if (to.name) {
    let s = to.name.toString()
    if (s.length > 1) {
      const x = s.indexOf('-')
      if (x > -1) {
        s = s.substring(0, x)
      }
      if (fleetPathPrefixes.includes(s)) {
        state.currentSpace = 'fleet'
      } else {
        state.currentSpace = ''
      }
    }
  }
  next()
})

const app = createApp(App)
app.config.globalProperties.$state = state
app.use(VueCookieNext).use(router).mount('#app')
