
import { Options, Vue } from 'vue-class-component'
import DeviceInfo from '@/components/fleet/DeviceInfo.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    DeviceInfo,
    TabBar
  }
})
export default class DeviceDetails extends Vue {}
