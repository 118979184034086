<template>
  <header>
    <NavBar />
  </header>

  <div v-if="isSignedIn" class="container" id="app-area">
    <router-view :key="$route.fullPath"/>
  </div>

  <footer>
    <Footer />
  </footer>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: { NavBar, Footer },
  mounted () {
    if (this.$cookie.getCookie('prefs.theme')) {
      document.documentElement.setAttribute('data-theme', this.$cookie.getCookie('prefs.theme'))
    } else {
      document.documentElement.setAttribute('data-theme', 'darkMode')
    }
  },
  computed: {
    isSignedIn () {
      return this.$state.isSignedIn
    },
    userInfo () {
      return this.$state.email
    }
  }
}
</script>

<style>
  @import './assets/styles/bootstrap-5.0.1.min.css';
  @import './assets/styles/agrology.css';
</style>
