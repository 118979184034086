<template>
  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Recent Device Reports
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'device-add' }" title="Add New Device"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Devices List" :spinOnCreation="true" @click="retrieveRecentDevices()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th style="width:10px !important;">Device ID</th>
            <th style="width:10px !important;"><ClockIcon class="svg-icon-label"/></th>
            <th style="width:10px !important;">Ranch</th>
            <th style="width:10px !important;">Type</th>
            <th style="width:10px !important;">Data</th>
            <th>Device Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!activeDevices || activeDevices.length === 0">
            <td class="subtle-info">
              No Devices Listed
            </td>
          </tr>
          <tr v-for="(device,i) of activeDevices" :key="device.id" v-bind:class="{'data-row-last': i+1 == activeDevices.length}">
            <td>
              <router-link :to="{  name: 'device-details', params: { id: device.id } }"
                v-bind:class="{ 'inactive': device.status === 'inactive' }"
              >
                {{ device.id }}
              </router-link>
            </td>
            <td>
              {{ getSince(device.report_time) }}
            </td>
            <td style="padding-right:15px">
              {{ device.ranch }}
            </td>
            <td style="padding-right:15px">
              {{ device.s_node_type }}
            </td>
            <td style="padding-right:15px">
              {{ device.data }}
            </td>
            <td>
              {{ device.notes }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import Spinner from '@/components/Spinner.vue'
import DeviceDataService from '@/services/fleet/DeviceDataService'
import ClockIcon from '@/components/svg/Clock'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'devices-recent-list',
  extends: BaseDeviceComponent,
  methods: {
    refreshList () {
      this.retrieveRecentDevices()
    },
    retrieveRecentDevices () {
      this.$refs.spinner.startSpinning()
      DeviceDataService.getRecent()
        .then(response => {
          this.isError = false
          this.devices = response.data
          this.$refs.spinner.stopSpinning()
        })
        .catch(e => {
          this.isError = true
          console.log(e)
          this.$refs.spinner.setError()
          this.$refs.spinner.stopSpinning()
        })
    }
  },
  mounted () {
    this.retrieveRecentDevices()
  },
  components: { Spinner, ClockIcon, AddIcon }
}
</script>
