<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="customer" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': customer.status === 'inactive'}">Customer</span> {{ customer.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="customer.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </div>

        <div class="row align-content-center" v-if="customer.description">
          <div class="col">
            <span class="list-heading-label">
              {{ customer.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="customer.notes">
          <div class="col node-notes node-customer-notes">
            {{ customer.notes }}
          </div>
        </div>

        <div class="row align-content-center" v-if="customer.contactName">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Point of Contact:</span> {{ customer.contactName }}
          </div>
        </div>

        <div class="row align-items-center" v-if="customer.email">
          <div class="col-auto contact-row-item padding-left-5 padding-right-5">
            <a v-bind:href="'mailto:' + customer.email">
              <EmailIcon class="button-icon" />
            </a>
          </div>
          <div class="col-auto padding-left-5">
            {{ customer.email }}
          </div>
        </div>

        <div class="row align-items-center" v-if="customer.phone">
          <div class="col-auto contact-row-item padding-left-5 padding-right-5">
            <a v-bind:href="'tel:' + customer.phone">
              <PhoneIcon class="button-icon" />
            </a>
          </div>
          <div class="col-auto padding-left-5">
            {{ customer.phone }}
          </div>
        </div>

        <div class="row" v-if="customer.state">
          <div class="col address-box">
            <div class="node-info-row" v-if="customer.address1">{{ customer.address1 }}</div>
            <div class="node-info-row" v-if="customer.address2">{{ customer.address2 }}</div>
            <div class="node-info-row">
              <span class="address-city" v-if="customer.city">{{ customer.city }}</span>
              <span class="address-state" v-if="customer.state">{{ customer.state }}</span>
              <span class="address-zip" v-if="customer.postalCode">{{ customer.postalCode }}</span>
              <span class="address-country" v-if="customer.country">{{ customer.country }}</span>
            </div>
          </div>
        </div>
      </div>
    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select v-model="customer.status" style="width: 100px" required>
              <GeneralStatusOptions />
          </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" id="cascade"/>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="customer.description"
              name="description"
              style="width:240px;"
              placeholder="Proper Name, Website, etc."
              maxlength="48"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="customer.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Customer that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="contactName">POC</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="contactName"
              v-model="customer.contactName"
              name="contactName"
              style="width:180px;"
              placeholder="Duke Ellington"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="phone">Phone</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="phone"
              v-model="customer.phone"
              name="phone"
              style="width:240px;"
              placeholder="888-555-1212"
              maxlength="18"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="email">Email</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="email"
              v-model="customer.email"
              name="email"
              style="width:240px;"
              placeholder="me@awesome-domain.com"
              maxlength="48"
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="address1">Address 1</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="address1"
              v-model="customer.address1"
              name="address1"
              style="width:240px;"
              placeholder="1209 DeSoto Ave."
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="address2">Address 2</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="address2"
              v-model="customer.address2"
              name="address2"
              style="width:240px;"
              placeholder="Suite C"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="city">City</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="city"
              v-model="customer.city"
              name="city"
              style="width:180px;"
              placeholder="Kannapolis"
              maxlength="32"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="state">State</label>
          <div class="col-auto form-right-input">
            <select id="state" v-model="customer.state" name="state" style="width:50px;">
              <StateOptions />
            </select>
          </div>
          <label class="col-form-label form-left-header" style="width: 65px;" for="country">Country</label>
          <div class="col-auto form-right-input">
            <select id="country" v-model="customer.country" name="country" style="width:50px;">
              <CountryOptions />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="postalCode">Zip/Postal</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="postalCode"
              v-model="customer.postalCode"
              name="postalCode"
              style="width:100px;"
              placeholder="28027-4737"
              maxlength="10"
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(customer.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseCustomerComponent from '@/components/fleet/BaseCustomerComponent.vue'
import CustomerDataService from '@/services/fleet/CustomerDataService'
import StateOptions from '@/components/selectOptions/StateOptions.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import EmailIcon from '@/components/svg/EmailIcon.vue'
import PhoneIcon from '@/components/svg/PhoneIcon.vue'
import CountryOptions from '@/components/selectOptions/CountryOptions.vue'

export default {
  name: 'customer',
  extends: BaseCustomerComponent,
  data () {
    return {
      customerBeforeEdit: {}
    }
  },
  methods: {
    getCustomer (id) {
      CustomerDataService.get(id)
        .then(response => {
          this.customer = response.data
          this.customerBeforeEdit = Object.assign({}, this.customer)
          this.$state.creationName = this.customer.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Customer')
        })
    },
    handleSubmit () {
      CustomerDataService.update(this.customer.id, this.customer)
        .then(response => {
          this.isError = false
          this.message = 'Customer Updated'
          this.setNavCustomer(this.$route.params.id)
          this.getCustomer(this.customer.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Customer Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.customer, this.customerBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getCustomer(this.$route.params.id)
    this.setNavCustomer(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
  },
  components: { StateOptions, CloseIcon, EditIcon, EmailIcon, PhoneIcon, GeneralStatusOptions, CountryOptions }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
