
import { Options, Vue } from 'vue-class-component'
import RanchInfo from '@/components/fleet/RanchInfo.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'
import DevicesByRanchList from '@/components/fleet/DevicesByRanchList.vue'
import GatewaysByRanchList from '@/components/fleet/GatewaysByRanchList.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    RanchInfo,
    AlertsByN,
    DevicesByRanchList,
    GatewaysByRanchList,
    TabBar
  }
})
export default class RanchDetails extends Vue {}
