<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="networkServer" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': networkServer.status === 'inactive'}">Network Server</span> {{ networkServer.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="networkServer.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </div>

        <div class="row align-content-center" v-if="networkServer.description">
          <div class="col">
            <span class="list-heading-label">
              {{ networkServer.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="networkServer.notes">
          <div class="col node-notes node-general-notes">
            {{ networkServer.notes }}
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="networkServer.apiEndpoint">
          <div class="col node-general-header">
            API URL:
          </div>
          <div class="col-auto">
            <a v-bind:href="networkServer.apiEndpoint">{{ networkServer.apiEndpoint }}</a>
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="networkServer.uiEndpoint">
          <div class="col node-general-header">
            UI URL:
          </div>
          <div class="col-auto">
            <a v-bind:href="networkServer.uiEndpoint">{{ networkServer.uiEndpoint }}</a>
          </div>
        </div>

      </div>

    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select v-model="networkServer.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" id="cascade"/>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="networkServer.description"
              name="description"
              style="width:240px;"
              placeholder="Proper Name, Website, etc."
              maxlength="48"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="networkServer.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Network Server that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="apiEndpoint">API URL</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="apiEndpoint"
              v-model="networkServer.apiEndpoint"
              name="apiEndpoint"
              style="width:240px;"
              placeholder="https://api.example.com/"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="uiEndpoint">UI URL</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="uiEndpoint"
              v-model="networkServer.uiEndpoint"
              name="uiEndpoint"
              style="width:240px;"
              placeholder="https://ui.example.com/"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(networkServer.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseNetworkServerComponent from '@/components/fleet/BaseNetworkServerComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'

export default {
  name: 'networkServer-info',
  extends: BaseNetworkServerComponent,
  data () {
    return {
      networkServerBeforeEdit: {}
    }
  },
  methods: {
    getNetworkServer (id) {
      FleetDataService.getNetworkServer(id)
        .then(response => {
          this.networkServer = response.data
          this.networkServerBeforeEdit = Object.assign({}, this.networkServer)
          this.$state.creationName = this.networkServer.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Network Server')
        })
    },
    handleSubmit () {
      FleetDataService.updateNetworkServer(this.networkServer.id, this.networkServer)
        .then(response => {
          this.isError = false
          this.message = 'Network Server Updated'
          this.setNavNetworkServer(this.$route.params.id)
          this.getNetworkServer(this.networkServer.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Network Server Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.networkServer, this.networkServerBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getNetworkServer(this.$route.params.id)
    this.setNavNetworkServer(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
  },
  components: { CloseIcon, EditIcon, GeneralStatusOptions }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
