<template>
    <ul class="nav nav-tabs">
    <li class="nav-item">
        <router-link :to="{  path: '/devices' }" class="nav-link" v-bind:class="{'active': activeTab === 'devices'}">Devices</router-link>
    </li>
    <li class="nav-item">
        <router-link :to="{  name: 'ranch-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'ranches'}">Ranches</router-link>
    </li>
    <li class="nav-item">
        <router-link :to="{  name: 'customer-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'customers'}">Customers</router-link>
    </li>
    <li class="nav-item d-none d-sm-block">
        <router-link :to="{  name: 'gateway-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'gateways'}">Gateways</router-link>
    </li>
    <li class="nav-item d-none d-sm-block">
        <router-link :to="{  name: 'manufacturer-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'manufacturers'}">Manufacturers</router-link>
    </li>
    <li class="nav-item d-none d-sm-block">
        <router-link :to="{  name: 'network-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'networks'}">Networks</router-link>
    </li>
    <li class="nav-item dropdown d-block d-sm-none">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">More</a>
        <ul class="dropdown-menu">
            <li>
                <router-link :to="{  name: 'gateway-list' }" class="nav-link nav-menu-item" v-bind:class="{'active-menu': activeTab === 'gateways'}">Gateways</router-link>
            </li>
            <li>
                <router-link :to="{  name: 'network-list' }" class="nav-link nav-menu-item" v-bind:class="{'active-menu': activeTab === 'networks'}">Networks</router-link>
            </li>
            <li>
                <router-link :to="{  name: 'manufacturer-list' }" class="nav-link nav-menu-item" v-bind:class="{'active-menu': activeTab === 'manufacturers'}">Manufacturers</router-link>
            </li>
        </ul>
    </li>
    </ul>
</template>
<script>
export default {
  data () {
    return {
      activeTab: this.activeTabName
    }
  },
  props: ['activeTabName']
}
</script>
