<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="alert" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': alert.status === 'inactive'}">Alert</span> {{ getLastWord(alert.id, '-') }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting">

        <div class="row">
          <div class="col-auto">
            {{ alert.id }}
          </div>
        </div>

        <div class="row abutting">
          <div class="col-auto node-general-header">
            Status:
          </div>
          <div class="col-auto" v-bind:class="{'notable': alert.status === 'active'}">
              {{ alert.status }}
              <span v-if="alert.locked" class="notable bold margin-left-10"> (locked)</span>
          </div>
        </div>

        <div class="row align-content-center" v-if="alert.detail">
          <div class="col node-notes">
            {{ alert.detail }}
          </div>
        </div>

        <div class="row abutting">
          <div class="col-auto node-general-header">
            Start:
          </div>
          <div class="col-auto">
              {{ getFormattedDateSecs(alert.start) }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.status === 'resolved' && alert.end">
          <div class="col-auto node-general-header">
            End:
          </div>
          <div class="col-auto">
              {{ getFormattedDateSecs(alert.end) }}
          </div>
        </div>

        <div class="row abutting">
          <div class="col-auto node-general-header">
            Updated:
          </div>
          <div class="col-auto">
              {{ getFormattedDateSecs(alert.lastModified) }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.category">
          <div class="col-auto node-general-header">
            Category:
          </div>
          <div class="col-auto">
              {{ alert.category }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.threshold">
          <div class="col-auto node-general-header">
            Threshold:
          </div>
          <div class="col-auto">
              {{ alert.threshold }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.ranch">
          <div class="col-auto node-general-header">
            Ranch:
          </div>
          <div class="col-auto">
              {{ alert.ranch }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.customer">
          <div class="col-auto node-general-header">
            Customer:
          </div>
          <div class="col-auto">
              {{ alert.customer }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.scope || alert.elementID">
          <div class="col-auto node-general-header">
            Element:
          </div>
          <div class="col-auto">
              {{ alert.scope }}.{{ alert.elementID }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.source">
          <div class="col-auto node-general-header">
            Source:
          </div>
          <div class="col-auto">
              {{ alert.source }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.metric">
          <div class="col-auto node-general-header">
            Metric:
          </div>
          <div class="col-auto">
              {{ alert.metric }}
          </div>
        </div>

        <div class="row abutting" v-if="alert.value">
          <div class="col-auto node-general-header">
            Value:
          </div>
          <div class="col-auto">
              {{ alert.value }}
          </div>
        </div>

      </div>

      <div class="container abutting">
        <div class="row d-flex align-items-center no-border-bottom response-row">
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end" v-if="message">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
          <div style="width:100%;" class="col-auto buttons-right" v-if="!message">
            <a v-if="alert.locked" class="btn" @click.prevent="unlockAlert()">Unlock</a>
            <a v-if="!alert.locked && alert.status === 'resolved'" class="btn" @click.prevent="lockAlert()">Lock</a>
            <a v-if="alert.status !== 'resolved'" class="btn margin-left-10" @click.prevent="resolveAlert()">Resolve</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAlertComponent from '@/components/notifications/BaseAlertComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'

export default {
  name: 'alert-info',
  extends: BaseAlertComponent,
  methods: {
    getAlert (id) {
      NotificationsDataService.getAlert(id)
        .then(response => {
          this.alert = response.data
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Alert')
        })
    },
    resolveAlert () {
      NotificationsDataService.resolveAlert(this.alert.id)
        .then(response => {
          this.message = 'Alert set to Resolved.'
          this.getAlert(this.alert.id)
        })
        .catch(e => {
          this.message = 'Alert was not Resolved.'
          this.isError = true
        })
    },
    lockAlert () {
      NotificationsDataService.lockAlert(this.alert.id)
        .then(response => {
          this.message = 'Alert Locked.'
          this.getAlert(this.alert.id)
        })
        .catch(e => {
          this.message = 'Alert was not Locked.'
          this.isError = true
        })
    },
    unlockAlert () {
      NotificationsDataService.unlockAlert(this.alert.id)
        .then(response => {
          this.message = 'Alert Unlocked.'
          this.getAlert(this.alert.id)
        })
        .catch(e => {
          this.message = 'Alert was not Unlocked.'
          this.isError = true
        })
    }
  },
  mounted () {
    this.getAlert(this.$route.params.id)
    this.setNavAlert(this.getLastWord(this.$route.params.id, '-'))
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
  },
  components: { CloseIcon }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
