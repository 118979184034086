<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()">

        <template v-slot:header>
        Preferences
        </template>

        <template v-slot:body>

          <div class="row prefs-row">
            <div class="col-auto prefs-row-header">
              Inactive Items
            </div>
            <div class="col-auto">
              <span class="attribute-label-left">
                <input type="checkbox" v-model="$state.showInactiveNodes" />
              </span>
              Show
            </div>
          </div>

          <hr />

          <div class="row prefs-row">
            <div class="col-auto prefs-row-header">
              Theme
            </div>
            <div class="col-auto">

              <div v-for="(tn,i) of themes" :key="tn" v-bind:class="{'padding-top-10': i > 0}" >
                <span class="prefs-row-data">
                  <input type="radio" name="theme" v-bind:id="'theme-' + tn" @click="toggleTheme(tn)" v-bind:value="tn" v-model="theme"/>
                </span>
                <label v-bind:for="'theme-' + tn">{{ themeLabels[i] }}</label>
              </div>

            </div>
          </div>

        </template>

    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  data () {
    return {
      isBlocking: false,
      themes: ['darkMode', 'softDarkMode', 'lightMode', 'highContrastMode'],
      themeLabels: ['Dark', 'Soft Dark', 'Light', 'High-Contrast'],
      theme: 'darkMode'
    }
  },
  components: { Modal },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      if (this.$state.showInactiveNodes) {
        this.$cookie.setCookie('prefs.show_inactive', '1')
      } else {
        this.$cookie.removeCookie('prefs.show_inactive')
      }
      this.isBlocking = false
    },
    toggleTheme (theme) {
      document.documentElement.setAttribute('data-theme', theme)
      this.$cookie.setCookie('prefs.theme', theme)
    }
  },
  mounted () {
    if (this.$cookie.getCookie('prefs.show_inactive') === '1') {
      this.$state.showInactiveNodes = true
    }
    if (this.$cookie.getCookie('prefs.theme')) {
      this.theme = this.$cookie.getCookie('prefs.theme')
    }
  }
}
</script>
