<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Ranch</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form ranch-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">Ranch ID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="ranch-name"
            maxlength="28"
          />
        </div>
      </div>

        <div id="v-model-customers" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="customers">Customer</label>
          <div class="col-auto form-right-input">
            <select v-model="customerName" id="customers" style="width: 225px" required>
              <option v-for="customer in activeCustomers" :value="customer.id" :key="customer.id">
                {{ customer.id }}
              </option>
            </select>
          </div>
        </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="description">Description</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="description"
            v-model="description"
            name="description"
            style="width:225px;"
            placeholder="Proper Name, Website, etc."
            maxlength="48"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Ranch that Agrology may find useful in the future."
          />
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="contactName">Ranch POC</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="contactName"
            v-model="contactName"
            name="contactName"
            style="width:225px;"
            placeholder="Duke Ellington"
            maxlength="64"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="phone">Phone</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="phone"
            v-model="phone"
            name="phone"
            style="width:225px;"
            placeholder="888-555-1212"
            maxlength="18"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="email">Email</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="email"
            v-model="email"
            name="email"
            style="width:225px;"
            placeholder="me@awesome-domain.com"
            maxlength="48"
          />
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="address1">Address 1</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="address1"
            v-model="address1"
            name="address1"
            style="width:225px;"
            placeholder="1209 DeSoto Ave."
            maxlength="64"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="address2">Address 2</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="address2"
            v-model="address2"
            name="address2"
            style="width:225px;"
            placeholder="Suite C"
            maxlength="64"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="city">City</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="city"
            v-model="city"
            name="city"
            style="width:225px;"
            placeholder="Kannapolis"
            maxlength="32"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="state">State</label>
        <div class="col-auto form-right-input">
          <select id="state" v-model="state" name="state" style="width:50px;">
            <StateOptions />
          </select>
        </div>
        <label class="col-form-label form-left-header" style="width: 65px;" for="country">Country</label>
        <div class="col-auto form-right-input">
          <select id="country" v-model="country" name="country" style="width:50px;">
            <CountryOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="postalCode">Zip/Postal</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="postalCode"
            v-model="postalCode"
            name="postalCode"
            style="width:100px;"
            placeholder="28027-4737"
            maxlength="10"
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseRanchComponent from '@/components/fleet/BaseRanchComponent.vue'
import RanchDataService from '@/services/fleet/RanchDataService'
import StateOptions from '@/components/selectOptions/StateOptions.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import CountryOptions from '@/components/selectOptions/CountryOptions.vue'

export default {
  name: 'ranch-add',
  extends: BaseRanchComponent,
  components: { StateOptions, CloseIcon, GeneralStatusOptions, CountryOptions },
  data () {
    return {
      id: '',
      status: 'active',
      customerName: '',
      description: '',
      notes: '',
      contactName: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      country: 'US',
      state: '',
      postalCode: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        status: this.status,
        customer: this.customerName,
        description: this.description,
        notes: this.notes,
        contactName: this.contactName,
        phone: this.phone,
        email: this.email,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        country: this.country,
        state: this.state,
        postalCode: this.postalCode
      }
      RanchDataService.create(data)
        .then(response => {
          this.isError = false
          this.message = ''
          this.$state.crudMessage = 'Ranch Created Successfully'
          this.$router.push({ name: 'ranch-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          var msg = ''

          if (e.response && e.response.data) {
            if (e.response.data.message) {
              msg = e.response.data.message
            } else {
              msg = e.response.data
            }
            if (msg.includes('invalid character in proposed')) {
              this.message = 'Invalid ranch-name Formatting'
              return
            }
            if (msg.includes('BucketAlreadyOwnedByYou')) {
              this.message = 'Ranch Already Exists'
              return
            }
            if (msg.includes('BucketAlreadyExists')) {
              this.message = 'S3 Bucket taken. Try another name.'
              return
            }
          }
          this.message = 'Ranch Creation Failed'
          console.log(e.response)
        })
    }
  },
  mounted () {
    this.retrieveAllCustomers()
    if (this.$state.creationName) {
      this.customerName = this.$state.creationName
    }
  },
  created () {
    this.setNav('Fleet', 'Add Ranch')
  }
}
</script>

<style>
</style>
