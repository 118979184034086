<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div class="col-auto abutting" v-else-if="device">
    <div class="col-auto component-pane component-pane-narrow">
      <h5><span class="node-label" v-bind:class="{'inactive': device.status === 'inactive'}">Device</span> {{ device.id }}</h5>
      <!-- DISPLAY MODE BELOW -->
      <div class="data-table">
        <div class="container abutting" v-if="!inEditMode">

          <div class="row align-content-center" v-if="device.status === 'inactive'">
            <div class="col-auto node-inactive-header">
              INACTIVE
            </div>
          </div>

          <div class="row row-nomargin" v-if="device.notes">
            <div class="col node-notes node-device-notes">
              {{ device.notes }}
            </div>
          </div>

          <div class="row row-nomargin" v-if="ranch && ranch.notes">
            <div class="col node-notes node-ranch-notes">
              {{ ranch.notes }}
            </div>
          </div>

          <div class="row row-nomargin" v-if="customer && customer.notes">
            <div class="col node-notes node-customer-notes">
              {{ customer.notes }}
            </div>
          </div>

          <div class="row align-items-center abutting">
            <div class="col node-general-header">
              Ranch:
            </div>
            <div class="col-auto">
              <router-link v-bind:class="{'inactive': ranch && ranch.status === 'inactive'}"
                :to="{  name: 'ranch-details', params: { id: device.ranch } }">
                {{ device.ranch }}
              </router-link>
              <span class="inactive" v-if="ranch && ranch.status === 'inactive'"> (inactive)</span>
            </div>
          </div>

          <div class="row d-flex align-items-center abutting" v-if="device.lat && device.long">
            <div class="col-auto node-general-header">
              Lat/Long:
            </div>
            <div class="col-auto">
              {{ device.lat }}, {{ device.long }}
            </div>
            <div class="col d-flex justify-content-end">
              <span>
                <a v-bind:href="mapURL" target="device-loc-map">
                  <MapPin class="button-icon" />
                </a>
              </span>
            </div>
          </div>

          <div class="row abutting no-border-bottom">
            <div class="col-auto node-general-header">
              Hyphens:
            </div>
            <div class="col-auto">
                {{ hypenateDeviceId(device.id) }}
            </div>
          </div>
        </div>

      <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
        <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
          <div id="v-model-ranches" class="row form-group">
            <label class="col-form-label form-left-header label-required" for="ranches">Ranch</label>
            <div class="col-auto form-right-input">
              <select v-model="device.ranch" id="ranches" style="width: 225px" required>
                <option v-for="ranch in activeRanches" :value="ranch.id" :key="ranch.id">
                  {{ ranch.id }}
                </option>
              </select>
            </div>
          </div>

          <div class="row form-group">
            <label class="col-form-label form-left-header label-required" for="status">Status</label>
            <div class="col-auto form-right-input">
              <select id="status" name="status" v-model="device.status" style="width: 100px" required>
                <GeneralStatusOptions />
              </select>
            </div>
          </div>

          <div class="row form-group">
            <label class="col-form-label form-left-header" for="notes">Notes</label>
            <div class="col-auto form-right-input">
              <textarea
                id="notes"
                v-model="device.notes"
                name="notes"
                class="notes-box"
                placeholder="Enter any info about this Device that Agrology may find useful in the future."
              />
            </div>
          </div>

          <div class="row-auto abutting form-button-row">
            <div class="col-auto buttons-right">
              <button class="btn">Update</button>
              <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
            </div>
          </div>

        </form>

        <div class="container abutting"
          v-bind:class="{ 'd-none': message == '' && inEditMode }">
          <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
            v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
          >
            <div class="col-auto status-box" v-if="message">
              <span class="status-ok" v-if="!isError">✓</span>
              <span class="status-error" v-if="isError">❌</span>
              <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
            </div>
            <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <span class="subtle-info status-last-modified">{{ getSinceSecs(device.lastModified) }}</span>
            </div>
            <div class="col d-flex justify-content-end">
              <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
                <a @click="inEditMode=!inEditMode">
                  <EditIcon class="button-icon" />
                </a>
              </span>
              <span v-bind:class="{ 'd-none': message == '' }">
                <a @click="message='';isError=false">
                  <CloseIcon class="button-icon button-close" v-bind:class="{ 'status-error': isError }" />
                </a>
              </span>
            </div>
          </div>
        </div>

      </div>

      <AlertsByN entityType="Device" class="padding-top-15" />
    </div>
  </div>

  <div class="col-auto d-none d-md-block"></div>

  <div class="col-auto component-pane abutting" v-if="!majorError">

    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Most Recent Telemetry Report
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <Spinner title="Refresh Telemetry Info" :spinOnCreation="true" @click="getDevice(this.device.id)" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="container list-with-header-adder info-table" v-if="device">

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Device ID</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.id }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Gateway ID</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.gw_device_id }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Data Type</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.s_node_type }}</span>
        </div>
      </div>

      <hr class="info-sep"/>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Report Time</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.report_time_hr.replace(" UTC", "Z") }}</span>
          <span style="padding-left: 10px">({{ getSince(device.report_time) }})</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Ingest Time</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.ingest_time_hr.replace(" UTC", "Z") }}</span>
          <span style="padding-left: 10px">({{ getSince(device.ingest_time) }})</span>
        </div>
      </div>

      <hr class="info-sep"/>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Lat/Long</span>
        </div>
        <div class="col info-table-content" v-if="device.lat && device.long">
          <span>{{ device.lat }}, {{device.long}}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Battery</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.battery }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>DR</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.dr }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Frequency</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.freq }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Port</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.port }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>RSSI</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.rssi }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>SeqNo</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.seqno }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>SNR</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.snr }}</span>
        </div>
      </div>

      <hr class="info-sep"/>

      <div class="row justify-content-center info-table-row info-table-solo-row">
        <div class="col-auto">
          <span class="info-table-label" style="padding-right:10px;">Data</span>
          <span>{{ device.data }}</span>
        </div>
      </div>

      <hr class="info-sep"/>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Air Pressure</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.air_pressure }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Air Temperature</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.air_temp }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Humidity</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.humidity }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Soil Moisture</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.soil_moisture }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Soil Tension</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.soil_tension }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Soil Temperature</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.soil_temperature }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Soil Matric Pot.</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.soil_water_potential }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Irrigation Count</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.irrigation_cnt }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Irrigation Dur.</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.irrigation_dur }}</span>
        </div>
      </div>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>IR Ambient Temp.</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.ir_ambient_temp }}</span>
        </div>
      </div>

      <div class="row info-table-row padding-bottom-5">
        <div class="col info-table-label">
          <span>IR Object Temp.</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.ir_object_temp }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import DeviceDataService from '@/services/fleet/DeviceDataService'
import Spinner from '@/components/Spinner.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import MapPin from '@/components/svg/MapPin.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'

export default {
  name: 'device',
  extends: BaseDeviceComponent,
  data () {
    return {
      deviceBeforeEdit: {}
    }
  },
  methods: {
    handleSubmit () {
      DeviceDataService.update(this.device.id, this.device)
        .then(response => {
          this.refreshNavLabels()
          this.getDevice(this.device.id)
          this.message = 'Device Updated'
          this.isError = false
          this.inEditMode = false
        })
        .catch(e => {
          this.message = 'Device Update Failed'
          this.isError = true
          console.log(e)
        })
    },
    getDevice (id) {
      this.$refs.spinner.startSpinning()
      DeviceDataService.get(id)
        .then(response => {
          for (var r of this.ranches) {
            if (r.id === response.data.ranch) {
              this.ranch = r
              break
            }
          }
          for (var c of this.customers) {
            if (c.id === this.ranch.customer) {
              this.customer = c
              break
            }
          }
          this.isError = false
          this.device = response.data
          this.deviceBeforeEdit = Object.assign({}, this.device)
          if (this.device.lat && this.device.lat) {
            this.mapURL = 'https://www.google.com/maps/search/?api=1&query=' + this.device.lat + '%2c' + this.device.long
          }
          this.refreshNavLabels()
          this.$refs.spinner.stopSpinning()
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Device')
          this.$refs.spinner.setError()
          this.$refs.spinner.stopSpinning()
        })
    },
    getDeviceFromParams () {
      var id = this.$route.params.id
      id = id.replaceAll(' ', '').replaceAll('-', '').replaceAll(':', '')
      if (id !== this.$route.params.id) {
        this.$router.replace({ name: 'device-details', params: { id: id } })
      }
      this.getDevice(id)
    },
    handleCancel () {
      Object.assign(this.device, this.deviceBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
    this.retrieveAllCustomers(this.retrieveAllRanches(this.getDeviceFromParams))
  },
  components: { Spinner, CloseIcon, EditIcon, MapPin, AlertsByN, GeneralStatusOptions }
}
</script>

<style>
</style>
