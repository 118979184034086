
import { Options, Vue } from 'vue-class-component'
import GatewayAddForm from '@/components/fleet/GatewayAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    GatewayAddForm,
    TabBar
  }
})
export default class AddGateway extends Vue {}
