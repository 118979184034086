<template>
    <div class="navbar-nav d-block justify-content-start justify-items-start align-items-center" style="white-space:nowrap;" >

        <ul class="nav">
            <li id="l1-li" ref="l1-li" class="nav-item dropdown nav-breadcrumbs" v-if="$route && $route.name !== 'Home'">
                <span></span>
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" id="l1-s" ref="l1-s" aria-expanded="false"></a>
                <ul class="dropdown-menu" style="position:absolute;">
                    <li><a class="dropdown-item" href="#/fleet">Fleet</a></li>
                    <li><a class="dropdown-item" href="#/notifications">Notifications</a></li>
                    <li><a class="dropdown-item no-border-bottom" href="#/admin">Administration</a></li>
                </ul>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-lg-block d-xl-block" id="l2-li" ref="l2-li">
                <span id="l2-s" ref="l2-s" class="notable"></span>
                <a id="l2-a" ref="l2-a" href="#"></a>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-md-block d-lg-none">
                <span>...</span>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-lg-block d-xl-block" id="l3-li" ref="l3-li">
                <span id="l3-s" ref="l3-s" class="notable"></span>
                <a id="l3-a" ref="l3-a" href="#"></a>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-md-block d-lg-block d-xl-block" id="l4-li">
                <span id="l4-s" ref="l4-s" class="notable"></span>
                <a id="l4-a" ref="l4-a" href="#"></a>
            </li>
        </ul>

    </div>
</template>
<script>
export default {
  name: 'breadcrumbs',
  methods: {
  }
}
</script>
