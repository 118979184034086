<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Device</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form device-add-form">

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">Device ID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="Device ID (hexadecimal)"
            maxlength="24"
          />
        </div>
      </div>

      <div id="v-model-ranches" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="ranches">Ranch</label>
        <div class="col-auto form-right-input">
          <select v-model="ranchName" id="ranches" style="width: 225px" required>
            <option v-for="ranch in activeRanches" :value="ranch.id" :key="ranch.id">
              {{ ranch.id }}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Device that Agrology may find useful in the future."
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import DeviceDataService from '@/services/fleet/DeviceDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'

export default {
  name: 'device-add',
  extends: BaseDeviceComponent,
  data () {
    return {
      id: '',
      status: 'active',
      ranchName: '',
      notes: '',
      lat: '',
      long: '',
      powerSource: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        status: this.status,
        ranch: this.ranchName,
        notes: this.notes,
        lat: this.lat,
        long: this.long,
        powerSource: this.powerSource
      }
      DeviceDataService.create(data)
        .then(response => {
          this.isError = false
          this.message = ''
          if (response && response.data && response.data.url) {
            var x = response.data.url.lastIndexOf('/')
            if (x > -1) {
              this.id = response.data.url.substring(x + 1, response.data.url.length)
            }
          }
          this.$state.crudMessage = 'Device Created Successfully'
          this.$router.push({ name: 'device-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          var msg = ''
          if (e.response && e.response.data && e.response.data.message &&
            e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
            this.message = 'Device Already Exists'
            return
          }
          this.message = 'Device Creation Failed'
          console.log(e.response)
        })
    }
  },
  mounted () {
    this.retrieveAllRanches()
    if (this.$state.creationName) {
      this.ranchName = this.$state.creationName
    }
  },
  created () {
    this.setNav('Fleet', 'Add Device')
  },
  components: { CloseIcon, GeneralStatusOptions }
}
</script>

<style>
</style>
