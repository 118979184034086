import http from '@/http-common'
import qs from 'qs'

class DeviceDataService {
  get (id: string) {
    return http.get(`/devices/${id}`)
  }

  create (data: any) {
    return http.post('/devices', qs.stringify(data))
  }

  update (id: any, data: any) {
    return http.put(`/devices/${id}`, qs.stringify(data))
  }

  getAllByRanch (ranchName: string) {
    return http.get(`/ranches/${ranchName}/devices`)
  }

  getRecent () {
    return http.get('/devices/recent')
  }
}

export default new DeviceDataService()
