<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="ranch" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': ranch.status === 'inactive'}">Ranch</span> {{ ranch.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="ranch.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </div>

        <div class="row align-content-center" v-if="ranch.description">
          <div class="col">
            <span class="list-heading-label">
              {{ ranch.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="ranch.notes">
          <div class="col node-notes node-ranch-notes">
            {{ ranch.notes }}
          </div>
        </div>

        <div class="row align-content-center" v-if="customer && customer.notes">
          <div class="col node-notes node-customer-notes">
            {{ customer.notes }}
          </div>
        </div>

        <div class="row align-content-center">
          <div class="col">
            <span class="attribute-label attribute-label-left">Customer:</span>
            <router-link v-bind:class="{'inactive': customer && customer.status === 'inactive'}"
              :to="{  name: 'customer-details', params: { id: ranch.customer } }">
              {{ ranch.customer }}
            </router-link>
            <span class="inactive" v-if="customer && customer.status === 'inactive'"> (inactive)</span>
          </div>
        </div>
        <div class="row align-content-center" v-if="ranch.contactName">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Ranch Contact:</span> {{ ranch.contactName }}
          </div>
        </div>

        <div class="row align-items-center" v-if="ranch.email">
          <div class="col-auto contact-row-item padding-left-5 padding-right-5">
            <a v-bind:href="'mailto:' + ranch.email">
              <EmailIcon class="button-icon" />
            </a>
          </div>
          <div class="col-auto padding-left-5">
            {{ ranch.email }}
          </div>
        </div>

        <div class="row align-items-center" v-if="ranch.phone">
          <div class="col-auto contact-row-item padding-left-5 padding-right-5">
            <a v-bind:href="'tel:' + ranch.phone">
              <PhoneIcon class="button-icon" />
            </a>
          </div>
          <div class="col-auto padding-left-5">
            {{ ranch.phone }}
          </div>
        </div>

        <div class="row" v-if="ranch.state">
          <div class="col address-box">
            <div class="node-info-row" v-if="ranch.address1">{{ ranch.address1 }}</div>
            <div class="node-info-row" v-if="ranch.address2">{{ ranch.address2 }}</div>
            <div class="node-info-row">
              <span class="address-city" v-if="ranch.city">{{ ranch.city }}</span>
              <span class="address-state" v-if="ranch.state">{{ ranch.state }}</span>
              <span class="address-zip" v-if="ranch.postalCode">{{ ranch.postalCode }}</span>
              <span class="address-country" v-if="ranch.country">{{ ranch.country }}</span>
              <div class="col justify-items-right" style="text-align:right;margin-top:-30px;padding-right:5px;">
                <a v-bind:href="addressLink()">
                  <MapPin class="button-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select v-model="ranch.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" id="cascade"/>
          </div>
        </div>

        <div id="v-model-customers" class="row form-group">
          <label class="col-form-label form-left-header" for="customers">Customer</label>
          <div class="col-auto form-right-input">
            <select class="customer-select" id="customers" v-model="ranch.customer" required>
              <option v-for="customer in activeCustomers" :value="customer.id" :key="customer.id">
                {{ customer.id }}
              </option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="ranch.description"
              name="description"
              style="width:225px;"
              placeholder="Proper Name, Website, etc."
              maxlength="48"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="ranch.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Ranch that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="contactName">Ranch POC</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="contactName"
              v-model="ranch.contactName"
              name="contactName"
              style="width:225px;"
              placeholder="Duke Ellington"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="phone">Phone</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="phone"
              v-model="ranch.phone"
              name="phone"
              style="width:225px;"
              placeholder="888-555-1212"
              maxlength="18"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="email">Email</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="email"
              v-model="ranch.email"
              name="email"
              style="width:225px;"
              placeholder="me@awesome-domain.com"
              maxlength="48"
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="address1">Address 1</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="address1"
              v-model="ranch.address1"
              name="address1"
              style="width:225px;"
              placeholder="1209 DeSoto Ave."
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="address2">Address 2</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="address2"
              v-model="ranch.address2"
              name="address2"
              style="width:225px;"
              placeholder="Suite C"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="city">City</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="city"
              v-model="ranch.city"
              name="city"
              style="width:225px;"
              placeholder="Kannapolis"
              maxlength="32"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="state">State</label>
          <div class="col-auto form-right-input">
            <select id="state" v-model="ranch.state" name="state" style="width:50px;">
              <StateOptions />
            </select>
          </div>
          <label class="col-form-label form-left-header" style="width: 65px;" for="country">Country</label>
          <div class="col-auto form-right-input">
            <select id="country" v-model="ranch.country" name="country" style="width:50px;">
              <CountryOptions />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="postalCode">Zip/Postal</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="postalCode"
              v-model="ranch.postalCode"
              name="postalCode"
              style="width:100px;"
              placeholder="28027-4737"
              maxlength="10"
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(ranch.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseRanchComponent from '@/components/fleet/BaseRanchComponent.vue'
import RanchDataService from '@/services/fleet/RanchDataService'
import StateOptions from '@/components/selectOptions/StateOptions.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import EmailIcon from '@/components/svg/EmailIcon.vue'
import PhoneIcon from '@/components/svg/PhoneIcon.vue'
import MapPin from '@/components/svg/MapPin.vue'
import CountryOptions from '@/components/selectOptions/CountryOptions.vue'

export default {
  name: 'ranch',
  extends: BaseRanchComponent,
  components: { StateOptions, CloseIcon, EditIcon, EmailIcon, PhoneIcon, MapPin, GeneralStatusOptions, CountryOptions },
  data () {
    return {
      ranchBeforeEdit: {},
      customer: {}
    }
  },
  methods: {
    getRanch (id) {
      RanchDataService.get(id)
        .then(response => {
          this.customerNotes = ''
          for (var c of this.customers) {
            if (c.id === response.data.customer) {
              this.customer = c
              break
            }
          }
          this.ranch = response.data
          this.ranchBeforeEdit = Object.assign({}, this.ranch)
          this.$state.creationName = this.ranch.id
          this.setNavRanch(this.ranch.customer, this.ranch.id)
        })
        .catch(e => {
          this.handleError(e, 'Ranch')
        })
    },
    handleSubmit () {
      RanchDataService.update(this.ranch.id, this.ranch)
        .then(response => {
          this.isError = false
          this.message = 'Ranch Updated'
          this.getRanch(this.ranch.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Ranch Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.ranch, this.ranchBeforeEdit)
      this.inEditMode = false
    },
    getRanchFromParams () {
      this.getRanch(this.$route.params.id)
    },
    addressLink () {
      var addr = ''
      var sep = ''
      var x = 0
      if (this.ranch) {
        if (this.ranch.address1 !== '') {
          x = this.ranch.address1.codePointAt(0)
          if (x >= 48 && x <= 57) {
            addr = this.ranch.address1
            sep = ' '
          }
        } else if (this.ranch.address2 !== '') {
          x = this.ranch.address2.codePointAt(0)
          if (x >= 48 && x <= 57) {
            addr = this.ranch.address2
            sep = ' '
          }
        }
        if (this.ranch.city !== '') {
          addr += sep + this.ranch.city
        }
        if (this.ranch.state !== '') {
          addr += sep + this.ranch.state
        }
        if (this.ranch.postalCode !== '') {
          addr += ',' + this.ranch.postalCode
        }
        if (this.ranch.country !== '') {
          addr += sep + this.ranch.country
        }
      }
      return encodeURI('https://maps.google.com/?q=' + addr)
    }
  },
  mounted () {
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
    this.retrieveAllCustomers(this.getRanchFromParams)
  }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
