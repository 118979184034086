<template>
  <div class="container justify-content-center big-menu-list">
    <h4>System Administration</h4>
    <h5>Under Construction, check back soon!</h5>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'

export default class AdminHome extends Vue {
  mounted () {
    NavigationService.setNavHome()
  }
}
</script>
