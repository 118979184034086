<template>
  <TabBar activeTabName="ranches" />
  <div class="row justify-content-center abutting">
    <RanchMainList />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import RanchMainList from '@/components/fleet/RanchMainList.vue' // @ is an alias to /src
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    RanchMainList,
    TabBar
  }
})
export default class RanchesList extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Fleet', '')
  }
}
</script>
