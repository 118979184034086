<template>
  <div class="col-lg-auto abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Alerts for this {{ entityTypeName }}
      </h5>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th>Alert ID</th>
            <th><ClockIcon class="svg-icon-label"/></th>
            <th>Status</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!alerts || alerts.length === 0" class="data-row-last">
            <td class="subtle-info">
              No Alerts
            </td>
          </tr>
          <tr v-for="(alert,i) of alerts" :key="alert.id" v-bind:class="{'data-row-last': i+1 == alerts.length}">
            <td>
              <router-link :to="{  name: 'alert-details', params: { id: alert.id } }">
                {{ getLastWord(alert.id) }}
              </router-link>
            </td>
            <td>
              {{ getSinceSecs(alert.lastModified) }}
            </td>
            <td v-bind:class="{'notable': alert.status === 'active'}">
              {{ alert.status }}
            </td>
            <td>
              {{ alert.category }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import ClockIcon from '@/components/svg/Clock'
import BaseComponent from '@/components/BaseComponent'

export default {
  data () {
    return {
      alerts: [],
      entityTypeName: this.entityType
    }
  },
  name: 'alerts-by-n-list',
  extends: BaseComponent,
  props: ['entityType'],
  methods: {
    retrieveAlerts (id) {
      NotificationsDataService.getRecentAlerts(this.entityTypeName.toLowerCase(), id)
        .then(response => {
          this.alerts = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    refreshList () {
      this.retrieveAlerts(this.$route.params.id)
    }
  },
  mounted () {
    this.retrieveAlerts(this.$route.params.id)
  },
  components: { ClockIcon }
}
</script>
