
import { Options, Vue } from 'vue-class-component'
import ManufacturerAddForm from '@/components/fleet/ManufacturerAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    ManufacturerAddForm,
    TabBar
  }
})
export default class AddManufacturer extends Vue {}
