<template>

  <div class="col component-pane abutting" style="max-width:520px;">

    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Ranches under this Customer
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'ranch-add' }" title="Add New Ranch"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Devices List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>

    <ul class="list-group">
      <li v-if="!activeRanches || activeRanches.length === 0" class="list-group-item subtle-info">
          No Ranches Listed
      </li>
      <li class="list-group-item" v-for="ranch in activeRanches" :key="ranch.id">
        <router-link :to="{  name: 'ranch-details', params: { id: ranch.id } }"
          v-bind:class="{ 'inactive': ranch.status === 'inactive' }"
        >
        {{ ranch.id }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseRanchComponent from '@/components/fleet/BaseRanchComponent.vue'
import RanchDataService from '@/services/fleet/RanchDataService'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'ranches-by-customer-list',
  extends: BaseRanchComponent,
  methods: {
    retrieveRanches (id) {
      RanchDataService.getAllByCustomer(id)
        .then(response => {
          this.isError = false
          this.ranches = response.data
          this.$refs.spinner.stopSpinning()
        })
        .catch(e => {
          console.log(e)
          this.isError = true
          this.$refs.spinner.setError()
          this.$refs.spinner.stopSpinning()
        })
    },
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveRanches(this.$route.params.id)
    }

  },
  mounted () {
    this.refreshList()
  },
  components: { Spinner, AddIcon }
}
</script>
