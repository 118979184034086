
import { Options, Vue } from 'vue-class-component'
import NavigationService from '../services/NavigationService'

@Options({})
export default class Home extends Vue {
  mounted () {
    NavigationService.setNavHome()
  }
}
