
import { Options, Vue } from 'vue-class-component'
import ApnSubscriptionInfo from '@/components/notifications/ApnSubscriptionInfo.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ApnSubscriptionInfo,
    TabBar
  }
})
export default class TemplateDetails extends Vue {}
