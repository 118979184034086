
import { Options, Vue } from 'vue-class-component'
import NetworkServerInfo from '@/components/fleet/NetworkServerInfo.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    NetworkServerInfo,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
