<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Network Server</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form network-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">Name</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="network-name"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="description">Description</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="description"
            v-model="description"
            name="description"
            style="width:225px;"
            placeholder="Proper Name, Website, etc."
            maxlength="72"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Network Server that Agrology may find useful in the future."
          />
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="apiEndpoint">API URL</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="apiEndpoint"
            v-model="apiEndpoint"
            name="apiEndpoint"
            style="width:225px;"
            placeholder="https://api.example.com/"
            maxlength="64"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="uiEndpoint">UI URL</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="uiEndpoint"
            v-model="uiEndpoint"
            name="uiEndpoint"
            style="width:225px;"
            placeholder="https://ui.example.com/"
            maxlength="64"
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseNetworkServerComponent from '@/components/fleet/BaseNetworkServerComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'

export default {
  name: 'network-add',
  extends: BaseNetworkServerComponent,
  data () {
    return {
      id: '',
      status: 'active',
      description: '',
      notes: '',
      apiEndpoint: '',
      uiEndpoint: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        status: this.status,
        description: this.description,
        notes: this.notes,
        apiEndpoint: this.apiEndpoint,
        uiEndpoint: this.uiEndpoint
      }
      FleetDataService.createNetworkServer(data)
        .then(response => {
          this.isError = false
          this.message = ''
          this.$state.crudMessage = 'Network Server Created Successfully'
          this.$router.push({ name: 'network-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          if (e.resposne && e.response.data && e.response.data.message &&
            e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
            this.message = 'Network Server Already Exists'
            return
          }
          this.message = 'Network Server Creation Failed'
          console.log(e.response)
        })
    }
  },
  created () {
    this.setNav('Fleet', 'Add Network Server')
  },
  components: { CloseIcon, GeneralStatusOptions }
}
</script>

<style>
</style>
