import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
//
// Home
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
// Admin
import AdminHome from '../views/admin/AdminHome.vue'
//
// Fleet
import FleetHome from '../views/fleet/FleetHome.vue'
// Fleet - Customers
import CustomersList from '../views/fleet/CustomersList.vue'
import CustomerDetails from '../views/fleet/CustomerDetails.vue'
import AddCustomer from '../views/fleet/AddCustomer.vue'
// Fleet - Ranches
import RanchesList from '../views/fleet/RanchesList.vue'
import RanchDetails from '../views/fleet/RanchDetails.vue'
import AddRanch from '../views/fleet/AddRanch.vue'
// Fleet - Devices
import DeviceDetails from '../views/fleet/DeviceDetails.vue'
import AddDevice from '../views/fleet/AddDevice.vue'
// Fleet - Network Servers
import NetworkServersList from '../views/fleet/NetworkServersList.vue'
import NetworkServerDetails from '../views/fleet/NetworkServerDetails.vue'
import AddNetworkServer from '../views/fleet/AddNetworkServer.vue'
// Fleet - Manufacturers
import ManufacturersList from '../views/fleet/ManufacturersList.vue'
import ManufacturerDetails from '../views/fleet/ManufacturerDetails.vue'
import AddManufacturer from '../views/fleet/AddManufacturer.vue'
// Fleet - Gateways
import GatewaysList from '../views/fleet/GatewaysList.vue'
import GatewayDetails from '../views/fleet/GatewayDetails.vue'
import AddGateway from '../views/fleet/AddGateway.vue'
//
// Notifications
import NotificationsHome from '../views/notifications/NotificationsHome.vue'
// Notifications - Alerts
import AlertDetails from '../views/notifications/AlertDetails.vue'
// Notifications - Thresholds
import ThresholdsList from '../views/notifications/ThresholdsList.vue'
import ThresholdDetails from '../views/notifications/ThresholdDetails.vue'
import AddThreshold from '../views/notifications/AddThreshold.vue'
// Notifications - Templates
import TemplatesList from '../views/notifications/TemplatesList.vue'
import AddTemplate from '../views/notifications/AddTemplate.vue'
import TemplateDetails from '../views/notifications/TemplateDetails.vue'
// Notifications - Subscribers
import SubscribersList from '../views/notifications/SubscribersList.vue'
import ApnSubscriptionDetails from '../views/notifications/ApnSubscriptionDetails.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ranches',
    name: 'ranch-list',
    component: RanchesList
  },
  {
    path: '/fleet',
    name: 'fleet-home',
    alias: '/devices',
    component: FleetHome
  },
  {
    path: '/customers',
    name: 'customer-list',
    component: CustomersList
  },
  {
    path: '/customers/add',
    name: 'customer-add',
    component: AddCustomer
  },
  {
    path: '/customers/:id',
    name: 'customer-details',
    component: CustomerDetails
  },
  {
    path: '/ranches/add',
    name: 'ranch-add',
    component: AddRanch
  },
  {
    path: '/ranches/:id',
    name: 'ranch-details',
    component: RanchDetails
  },
  {
    path: '/devices/add',
    name: 'device-add',
    component: AddDevice
  },
  {
    path: '/devices/:id',
    name: 'device-details',
    component: DeviceDetails
  },
  {
    path: '/networks',
    name: 'network-list',
    component: NetworkServersList
  },
  {
    path: '/networks/add',
    name: 'network-add',
    component: AddNetworkServer
  },
  {
    path: '/networks/:id',
    name: 'network-details',
    component: NetworkServerDetails
  },
  {
    path: '/manufacturers',
    name: 'manufacturer-list',
    component: ManufacturersList
  },
  {
    path: '/manufacturers/add',
    name: 'manufacturer-add',
    component: AddManufacturer
  },
  {
    path: '/manufacturers/:id',
    name: 'manufacturer-details',
    component: ManufacturerDetails
  },
  {
    path: '/gateways',
    name: 'gateway-list',
    component: GatewaysList
  },
  {
    path: '/gateways/add',
    name: 'gateway-add',
    component: AddGateway
  },
  {
    path: '/gateways/:id',
    name: 'gateway-details',
    component: GatewayDetails
  },
  {
    path: '/notifications',
    name: 'notifications-home',
    alias: '/alerts',
    component: NotificationsHome
  },
  {
    path: '/thresholds',
    name: 'threshold-list',
    component: ThresholdsList
  },
  {
    path: '/thresholds/add',
    name: 'threshold-add',
    component: AddThreshold
  },
  {
    path: '/thresholds/:id',
    name: 'threshold-details',
    component: ThresholdDetails
  },
  {
    path: '/templates',
    name: 'template-list',
    component: TemplatesList
  },
  {
    path: '/templates/add',
    name: 'template-add',
    component: AddTemplate
  },
  {
    path: '/templates/:id',
    name: 'template-details',
    component: TemplateDetails
  },
  {
    path: '/alerts/:id',
    name: 'alert-details',
    component: AlertDetails
  },
  {
    path: '/subscriptions',
    name: 'subscription-list',
    component: SubscribersList
  },
  {
    path: '/subscriptions/apn/:deviceToken/:bundleID',
    name: 'subscription-details',
    component: ApnSubscriptionDetails
  },
  {
    path: '/admin',
    name: 'admin-home',
    component: AdminHome
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
