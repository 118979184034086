<template>
    <div class="footer">
      <span class="padding-right-4">
        © {{ new Date().getFullYear() }} Agrology
      </span>
      <span class="padding-right-4">
        •
      </span>
      <span v-if="email" class="padding-right-4">
        {{ email }}
      </span>
      <span v-if="email" class="padding-right-4">
        •
      </span>
      <span v-if="email">
        <a @click.prevent="logout()" href='#' class="inactive">Logout</a>
      </span>
    </div>
</template>

<script>
import auth from '../app/auth'

export default {
  name: 'footer',
  computed: {
    email () {
      return this.$state.email
    }
  },
  methods: {
    logout () {
      auth.logout()
    }
  }
}
</script>
