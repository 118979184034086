<template>
  <div class="col component-pane abutting">
    <div class="d-md-none">
      <h5>
        Apple Notification Subscriptions
      </h5>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-none d-md-inline">
        <h5>
          Apple Notification Subscriptions
        </h5>
      </div>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <div class="d-flex justify-content-between align-items-center inline-form margin-right-15" id="apnsubs-filter-box">
          <span class="inline-form-label">Filter</span>
          <select v-model="filterBy" class="inline-form-control" name="filterBy" id="filterBy" ref="filterBy" style="width:55px;">
            <option value="bundleID">bundle</option>
            <option value="ranch">ranch</option>
            <option value="email">email</option>
          </select>
          <span class="inline-form-label">On</span>
          <input v-model="filterEmailVal" type="text" name="filterEmail"  style="width:145px;" v-if="filterBy === 'email'" />
          <select v-model="filterBundleIDVal" @change="refreshList()" class="inline-form-control no-padding-right" style="width:145px;"
            v-bind:class="{ 'd-none': filterBy !== 'bundleID' }">
            <option value="ag.agrology.agrology">ag.agrology.agrology</option>
          </select>
          <select class="inline-form-control no-padding-right" style="width:145px;" name="filterBundles" id="filterBundles" ref="filterBundles" v-if="filterBy === 'ranch'">
            <option value="not-ready-yet">under-development</option>
          </select>
        </div>
        <router-link :to="{  name: 'template-add' }" title="Add New Subscription"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Subscriptions List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th style="width:10px !important;">Token</th>
            <th style="width:10px !important;"><ClockIcon class="svg-icon-label"/></th>
            <th style="width:10px !important;">Email</th>
            <th style="width:10px !important;">Ranches</th>
            <th style="width:10px !important;">BundleID</th>
            <th>DeviceType</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!apnSubscriptions || apnSubscriptions.length === 0">
            <td class="subtle-info">
              No Subscriptions Listed
            </td>
          </tr>
          <tr v-for="(subscription,i) of apnSubscriptions" :key="subscription.deviceToken+'.'+subscription.bundleID" v-bind:class="{'data-row-last': i+1 == apnSubscriptions.length}">
            <td>
              <router-link :to="{  name: 'subscription-details', params: { deviceToken: subscription.deviceToken, bundleID: subscription.bundleID } }">
                {{ getLast10(subscription.deviceToken) }}
              </router-link>
            </td>
            <td>
              {{ getSinceSecs(subscription.lastModified) }}
            </td>
            <td style="padding-right:15px">
              {{ subscription.email }}
            </td>
            <td style="padding-right:15px">
              {{ subscription.ranches }}
            </td>
            <td style="padding-right:15px">
              {{ subscription.bundleID }}
            </td>
            <td style="padding-right:15px">
              {{ subscription.deviceType }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseSubscriptionComponent from '@/components/notifications/BaseSubscriptionComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'
import ClockIcon from '@/components/svg/Clock'

export default {
  name: 'subscriptions-recent-list',
  extends: BaseSubscriptionComponent,
  data () {
    return {
      filterBy: 'bundleID',
      filterOn: '',
      filterRanchVal: '',
      filterEmailVal: '',
      filterBundleIDVal: 'ag.agrology.agrology'
    }
  },
  methods: {
    refreshList () {
      if (this.filterBy === 'ranch') {
        this.filterOn = this.filterRanchVal
      } else if (this.filterBy === 'email') {
        this.filterOn = this.filterEmailVal
      } else {
        this.filterOn = this.filterBundleIDVal
      }
      if (this.filterOn && this.filterOn !== '') {
        this.$refs.spinner.startSpinning()
        this.retrieveFilteredApnSubscriptions(this.filterBy, this.filterOn, this.$refs.spinner.stopSpinning)
      }
    }
  },
  mounted () {
    this.setNavSub('subscriptions')
    this.refreshList()
  },
  components: { Spinner, ClockIcon, AddIcon }
}
</script>
