<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import NavigationService from '@/services/NavigationService'
import DeviceDataService from '@/services/fleet/DeviceDataService'

export default {
  name: 'base-device-component',
  extends: BaseFleetComponent,
  data () {
    return {
      lat: '',
      long: ''
    }
  },
  methods: {
    updateBreadcrumbs () {
      if (this.device) {
        this.$state.currentSpace = 'fleet'
        NavigationService.setNavLevel4(
          'Fleet', this.device.customer, this.device.ranch, this.device.id,
          '#/fleet', '#/customers/' + this.customer.id, '#/ranches/' + this.ranch.id, '')
      }
    },
    refreshNavLabels () {
      this.customerFromRanch()
      this.updateBreadcrumbs()
    }
  }
}
</script>
