<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Template</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form template-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">Name</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:250px;"
            placeholder="template.name"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="description">Description</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="description"
            v-model="description"
            name="description"
            style="width:250px;"
            placeholder="Short &amp; Human-Readable"
            maxlength="72"
          />
        </div>
      </div>

      <div id="v-model-mediums" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="mediums">Medium</label>
        <div class="col-auto form-right-input">
          <select v-model="medium" id="mediums" style="width: 250px" required>
            <NotificationMediums />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="subject">Subject</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="subject"
            v-model="subject"
            name="subject"
            style="width:250px;"
            placeholder="Notification Subject"
            maxlength="255"
          />
        </div>
      </div>

      <div class="row form-group">
        <div style="max-width:280px;">
          <label class="col-form-label label-required" for="body">Body</label>
        </div>
        <div class="width-350">
          <textarea
            id="body"
            v-model="body"
            name="body"
            class="notes-box width-345 height-200"
            placeholder="Enter the body of Notification Template"
          />
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header width-55" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box width-270"
            placeholder="Enter any info about this Template that Agrology may find useful in the future."
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseTemplateComponent from '@/components/notifications/BaseTemplateComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import NotificationMediums from '@/components/selectOptions/NotificationMediums.vue'

export default {
  name: 'template-add',
  extends: BaseTemplateComponent,
  data () {
    return {
      id: '',
      description: '',
      notes: '',
      medium: '',
      subject: '',
      body: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        description: this.description,
        notes: this.notes,
        medium: this.medium,
        subject: this.subject,
        body: this.body
      }
      NotificationsDataService.createTemplate(data)
        .then(response => {
          this.isError = false
          this.message = ''
          this.$state.crudMessage = 'Template Created Successfully'
          this.$router.push({ name: 'template-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          if (e.resposne && e.response.data && e.response.data.message &&
            e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
            this.message = 'Template Already Exists'
            return
          }
          this.message = 'Template Creation Failed'
          console.log(e)
        })
    }
  },
  mounted () {
    this.setNav('Notifications', 'Add Template')
  },
  components: { CloseIcon, NotificationMediums }
}
</script>

<style>
</style>
