<template>
  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Templates
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'template-add' }" title="Add New Template"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Templates List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th style="width:10px !important;">Template ID</th>
            <th style="width:10px !important;">Medium</th>
            <th style="width:10px !important;">Description</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!templates || templates.length === 0">
            <td class="subtle-info">
              No Templates Listed
            </td>
          </tr>
          <tr v-for="(template,i) of templates" :key="template.id" v-bind:class="{'data-row-last': i+1 == templates.length}">
            <td>
              <router-link :to="{  name: 'template-details', params: { id: template.id } }">
                {{ template.id }}
              </router-link>
            </td>
            <td style="padding-right:15px">
              {{ template.medium }}
            </td>
            <td style="padding-right:15px">
              {{ template.description }}
            </td>
            <td style="padding-right:15px">
              {{ template.notes }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseTemplateComponent from '@/components/notifications/BaseTemplateComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'templates-recent-list',
  extends: BaseTemplateComponent,
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveAllTemplates(this.$refs.spinner.stopSpinning)
    }
  },
  mounted () {
    this.setNavSub('templates')
    this.refreshList()
  },
  components: { Spinner, AddIcon }
}
</script>
