<template>
    <div class="form-inline nav-search d-block d-large-none align-content-center align-items-center">
        <input type="text"
        v-model="deviceLookupId" @keyup.enter="searchDevice" ref="deviceIdInput"
        placeholder="Search Device ID" class="device-search device-search-input" style="margin-top:1px;" />
    </div>
</template>

<script>
export default {
  name: 'device-search-box',
  data () {
    return {
      deviceLookupId: ''
    }
  },
  methods: {
    searchDevice () {
      if (this.deviceLookupId === '') {
        return
      }
      this.deviceLookupId = this.deviceLookupId.replaceAll(' ', '').replaceAll('-', '').replaceAll(':', '')
      this.$router.push({ name: 'device-details', params: { id: this.deviceLookupId } })
      this.deviceLookupId = ''
      this.$refs.deviceIdInput.blur()
    }
  }
}
</script>
