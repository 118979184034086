
import { Options, Vue } from 'vue-class-component'
import TemplateInfo from '@/components/notifications/TemplateInfo.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    TemplateInfo,
    TabBar
  }
})
export default class TemplateDetails extends Vue {}
