
import { Options, Vue } from 'vue-class-component'
import CustomerMainList from '@/components/fleet/CustomerMainList.vue' // @ is an alias to /src
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    CustomerMainList,
    TabBar
  }
})
export default class CustomersList extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Fleet', '')
  }
}
