
import { Options, Vue } from 'vue-class-component'
import ApnSubscriberMainList from '@/components/notifications/ApnSubscriberMainList.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ApnSubscriberMainList,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
