
import { Options, Vue } from 'vue-class-component'
import NetworkServerMainList from '@/components/fleet/NetworkServerMainList.vue' // @ is an alias to /src
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    NetworkServerMainList,
    TabBar
  }
})
export default class NetworkServersList extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Fleet', '')
  }
}
