
import { Options, Vue } from 'vue-class-component'
import ManufacturerInfo from '@/components/fleet/ManufacturerInfo.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    ManufacturerInfo,
    TabBar
  }
})
export default class ManufacturerDetails extends Vue {}
