
import { Options, Vue } from 'vue-class-component'
import RecentAlertList from '@/components/notifications/RecentAlertList.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    RecentAlertList,
    TabBar
  }
})
export default class NotificationsHome extends Vue {
}
