<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="threshold" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': threshold.enabled !== 1}">Threshold</span> {{ threshold.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="threshold.enabled !== 1">
          <div class="col-auto node-inactive-header">
            DISABLED
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.description">
          <div class="col">
            <span class="list-heading-label">
              {{ threshold.description }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.notes">
          <div class="col node-notes node-general-notes">
            {{ threshold.notes }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.category">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Category:</span> {{ threshold.category }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.scope">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Scope:</span> {{ threshold.scope }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.filter">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Filter:</span> {{ threshold.filter }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.attribute">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Attribute:</span> {{ threshold.attribute }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.source">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Source:</span> {{ threshold.source }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.metric">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Threshold:</span>
            {{ threshold.metric }} {{ threshold.operator }} {{ threshold.startVal }}
            <span v-if="threshold.operator === 'btw'"> and {{ threshold.endVal}}</span>
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.compareTo">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Compare To:</span> {{ threshold.compareTo }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.triggerTrendSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Trigger Trend:</span> {{ threshold.triggerTrendSecs }}s
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.triggerTemplate">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Trigger Template:</span> {{ threshold.triggerTemplate }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.resolveTrendSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Resolve Trend:</span> {{ threshold.resolveTrendSecs }}s
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.resolveTemplate">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Resolved Template:</span> {{ threshold.resolveTemplate }}
          </div>
        </div>

        <div class="row align-content-center" v-if="threshold.flapBufferSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Flapping Trend:</span> {{ threshold.flapBufferSecs }}s
          </div>
        </div>

      </div>
    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="threshold.description"
              name="description"
              style="width:210px;"
              placeholder="Short and Human-Readable"
              maxlength="72"
            />
          </div>
        </div>

        <div id="v-model-enabled" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="enabled">Monitoring</label>
          <div class="col-auto form-right-input">
            <input type="radio" class="margin-right-5" value="1" v-model="threshold.enabled" id="enabled" />Enabled
            &nbsp;&nbsp;&nbsp;
            <input type="radio" class="margin-right-5" value="0" v-model="threshold.enabled" id="disabled" />Disabled
          </div>
        </div>

        <div id="v-model-categories" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="categories">Category</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.category" id="categories" style="width: 210px" required>
              <NotificationCategories />
            </select>
          </div>
        </div>

        <div id="v-model-scopes" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="scopes">Scope:</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.scope" id="scope" style="width: 210px" required>
              <NotificationScopes />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="filter">Filter</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="filter"
              v-model="threshold.filter"
              name="filter"
              style="width:210px;"
              placeholder="ranch-or-device-name-etc"
              maxlength="72"
            />
          </div>
        </div>

        <div class="row form-group d-none">
          <label class="col-form-label form-left-header width-115" for="attribute">Attribute</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="attribute"
              v-model="threshold.attribute"
              name="attribute"
              style="width:210px;"
              placeholder="Optional/Experimental"
              maxlength="72"
            />
          </div>
        </div>

        <hr />
        <div class="form-section-heading">Condition</div>

        <div id="v-model-sources" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="sources">Source</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.source" id="sources" style="width: 210px" required>
              <NotificationSources />
            </select>
          </div>
        </div>

        <div id="v-model-metrics" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="metrics">Metric</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.metric" id="metrics" style="width: 210px" required>
              <NotificationMetricsTelemetry />
            </select>
          </div>
        </div>

        <div id="v-model-operators" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="operators">Operator</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.operator" id="operators" style="width: 210px" required>
              <ThresholdOperators />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="attribute">Value</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="startVal"
              v-model="threshold.startVal"
              name="startVal"
              style="width:210px;"
              placeholder="3.14159"
              maxlength="32"
            />
            <p v-if="threshold.operator === 'btw'">to</p>
            <input
              v-if="threshold.operator === 'btw'"
              type="text"
              id="endVal"
              v-model="threshold.endVal"
              name="endVal"
              style="width:210px;"
              placeholder="3.14159"
              maxlength="32"
            />
          </div>
        </div>

        <div id="v-model-comparators" class="row form-group">
          <label class="col-form-label form-left-header width-115 label-required" for="comparators">Compare To</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.compareTo" id="comparators" style="width: 210px" required>
              <ThresholdComparators />
            </select>
          </div>
        </div>

        <hr />
        <div class="form-section-heading">Triggering</div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="triggerTrendSecs">Minimum Trend</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="triggerTrendSecs"
              v-model="threshold.triggerTrendSecs"
              name="triggerTrendSecs"
              style="width:100px;"
              placeholder="0"
              maxlength="72"
            /> seconds
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="triggerTemplate">Notif. Template</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.triggerTemplate" id="triggerTemplate" style="width: 210px">
              <option v-for="tmpl in templates" :value="tmpl.id" :key="tmpl.id">
                {{ tmpl.id }}
              </option>
            </select>
          </div>
        </div>

        <hr />

        <div class="form-section-heading">Resolution</div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="triggerTrendSecs">Minimum Trend</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="resolveTrendSecs"
              v-model="threshold.resolveTrendSecs"
              name="resolveTrendSecs"
              style="width:100px;"
              placeholder="0"
              maxlength="72"
            /> seconds
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="resolveTemplate">Notif. Template</label>
          <div class="col-auto form-right-input">
            <select v-model="threshold.resolveTemplate" id="resolveTemplate" style="width: 210px">
              <option v-for="tmpl in templates" :value="tmpl.id" :key="tmpl.id">
                {{ tmpl.id }}
              </option>
            </select>
          </div>
        </div>

        <hr />

        <div class="form-section-heading">Flapping</div>

        <div class="row form-group">
          <label class="col-form-label form-left-header width-115" for="triggerTrendSecs">Minimum Trend</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="flapBufferSecs"
              v-model="threshold.flapBufferSecs"
              name="flapBufferSecs"
              style="width:100px;"
              placeholder="0"
              maxlength="72"
            /> seconds
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header width-55" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="threshold.notes"
              name="notes"
              class="notes-box width-270"
              placeholder="Enter any info about this Threshold that Agrology may find useful in the future."
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(threshold.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseThresholdComponent from '@/components/notifications/BaseThresholdComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import NotificationCategories from '@/components/selectOptions/NotificationCategories.vue'
import NotificationSources from '@/components/selectOptions/NotificationSources.vue'
import NotificationMetricsTelemetry from '@/components/selectOptions/NotificationMetricsTelemetry.vue'
import NotificationScopes from '@/components/selectOptions/NotificationScopes.vue'
import ThresholdOperators from '@/components/selectOptions/ThresholdOperators.vue'
import ThresholdComparators from '@/components/selectOptions/ThresholdComparators.vue'

export default {
  name: 'threshold',
  extends: BaseThresholdComponent,
  data () {
    return {
      thresholdBeforeEdit: {}
    }
  },
  methods: {
    getThreshold (id) {
      NotificationsDataService.getThreshold(id)
        .then(response => {
          this.threshold = response.data
          this.thresholdBeforeEdit = Object.assign({}, this.threshold)
          this.$state.creationName = this.threshold.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Threshold')
        })
    },
    handleSubmit () {
      NotificationsDataService.updateThreshold(this.threshold.id, this.threshold)
        .then(response => {
          this.isError = false
          this.message = 'Threshold Updated'
          this.setNavThreshold(this.$route.params.id)
          this.getThreshold(this.threshold.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Threshold Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.threshold, this.thresholdBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getThreshold(this.$route.params.id)
    this.setNavThreshold(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
    this.retrieveAllTemplates()
  },
  components: { CloseIcon, EditIcon, NotificationCategories, NotificationSources, NotificationMetricsTelemetry, NotificationScopes, ThresholdOperators, ThresholdComparators }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
