<template>
  <PreferencesPane ref="prefspane" />
  <div class="nav-header">
    <div class="container-xl d-lg-flex flex-items-center p-responsive nav-container">
      <nav class="navbar navbar-expand-lg navbar-dark branded-navbar justify-content-between">
        <div class="d-flex align-content-center align-items-center">
          <router-link :to="{  name: 'Home' }" class="navbar-brand">
            <LogoIcon alt="Agrology Logo Icon" height="24" width="24" class="navbar-logo" />
          </router-link>
          <router-link :to="{  name: 'Home' }" class="d-none d-sm-block">
            <LogoText height="17" width="80" class="navbar-logo-text" />
          </router-link>
          <router-link :to="{  name: 'Home' }" class="d-block d-sm-none d-md-none d-lg-none d-xl-none" v-if="$state.currentSpace === ''">
            <LogoText height="17" width="80" class="navbar-logo-text" />
          </router-link>
          <Breadcrumbs v-if="isSignedIn" />
        </div>
        <div class="nav-right-area justify-content-end align-items-center">
          <DeviceSearchBox v-if="$state.currentSpace === 'fleet'" />
          <a @click="showPrefsPane()">
            <CogIcon alt="Preferences" class="navbar-prefs" />
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import PreferencesPane from '@/components/PreferencesPane.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import DeviceSearchBox from '@/components/fleet/DeviceSearchBox.vue'
import LogoIcon from '@/components/svg/LogoIcon.vue'
import LogoText from '@/components/svg/LogoText.vue'
import CogIcon from '@/components/svg/Cog.vue'

export default {
  name: 'navbar',
  components: { PreferencesPane, Breadcrumbs, DeviceSearchBox, LogoIcon, LogoText, CogIcon },
  methods: {
    showPrefsPane () {
      this.$refs.prefspane.show()
    }
  },
  computed: {
    isSignedIn () {
      return this.$state.isSignedIn
    }
  }
}
</script>
