
import { Options, Vue } from 'vue-class-component'
import AlertInfo from '@/components/notifications/AlertInfo.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    AlertInfo,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
