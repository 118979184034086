<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="gateway" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label" v-bind:class="{'inactive': gateway.status === 'inactive'}">Gateway</span> {{ gateway.id }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting" v-if="!inEditMode">

        <div class="row align-content-center" v-if="gateway.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </div>

        <div class="row align-content-center" v-if="gateway.notes">
          <div class="col node-notes node-general-notes">
            {{ gateway.notes }}
          </div>
        </div>

        <div class="row align-items-center abutting">
          <div class="col node-general-header-105">
            Ranch:
          </div>
          <div class="col-auto">
            <router-link v-bind:class="{'inactive': ranch && ranch.status === 'inactive'}"
              :to="{  name: 'ranch-details', params: { id: gateway  .ranch } }">
              {{ gateway.ranch }}
            </router-link>
            <span class="inactive" v-if="ranch && ranch.status === 'inactive'"> (inactive)</span>
          </div>
        </div>

        <div class="row align-items-center abutting">
          <div class="col node-general-header-105">
            Manufacturer:
          </div>
          <div class="col-auto">
            <router-link v-bind:class="{'inactive': manufacturer && manufacturer.status === 'inactive'}"
              :to="{  name: 'manufacturer-details', params: { id: gateway  .manufacturer } }">
              {{ gateway.manufacturer }}
            </router-link>
            <span class="inactive" v-if="manufacturer && manufacturer.status === 'inactive'"> (inactive)</span>
          </div>
        </div>

        <div class="row align-items-center abutting">
          <div class="col node-general-header-105">
            Model:
          </div>
          <div class="col-auto">
            {{ gateway.model }}
          </div>
        </div>

        <div class="row align-items-center abutting">
          <div class="col node-general-header-105">
            Power:
          </div>
          <div class="col-auto">
            {{ gateway.powerSource }}
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="gateway.imei">
          <div class="col node-general-header-105">
            IMEI:
          </div>
          <div class="col-auto">
            {{ gateway.imei }}
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="gateway.iccid">
          <div class="col node-general-header-105">
            ICCID:
          </div>
          <div class="col-auto">
            {{ gateway.iccid }}
          </div>
        </div>

        <div class="row align-items-center abutting">
          <div class="col node-general-header-105">
            Network:
          </div>
          <div class="col-auto">
            <router-link v-bind:class="{'inactive': networkServer && networkServer.status === 'inactive'}"
              :to="{  name: 'network-details', params: { id: gateway.networkServer } }">
              {{ gateway.networkServer }}
            </router-link>
            <span class="inactive" v-if="networkServer && networkServer.status === 'inactive'"> (inactive)</span>
          </div>
        </div>

        <div class="row align-items-center abutting" v-if="gateway.networkAccess">
          <div class="col node-general-header-105">
            Connectivity:
          </div>
          <div class="col-auto">
            {{ gateway.networkAccess }}
          </div>
        </div>

      </div>

    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>

        <div id="v-model-ranches" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="ranches">Ranch</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.ranch" id="ranches" style="width: 225px" required>
              <option v-for="ranch in activeRanches" :value="ranch.id" :key="ranch.id">
                {{ ranch.id }}
              </option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" id="cascade"/>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="gateway.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Gateway that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div id="v-model-manufacturers" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="manufacturers">Manuf:</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.manufacturer" id="manufacturers" style="width: 225px" required>
              <option v-for="manufacturer in activeManufacturers" :value="manufacturer.id" :key="manufacturer.id">
                {{ manufacturer.id }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-mfmodels" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="mfmodels">Model</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.model" id="mfmodels" style="width: 225px" required>
              <option v-for="(mfmodel) in mfmodels" :value="mfmodel" :key="mfmodel">
                {{ mfmodel.trim() }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-powers" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="powers">Power</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.powerSource" id="powers" style="width: 225px" required>
              <PowerSourceOptions />
            </select>
          </div>
        </div>

        <hr />

        <div id="v-model-networkServers" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="networkServers">Network:</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.networkServer" id="networkServers" style="width: 225px" required>
              <option v-for="networkServer in activeNetworkServers" :value="networkServer.id" :key="networkServer.id">
                {{ networkServer.id }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-networkAccesses" class="row form-group">
          <label class="col-form-label form-left-header label-required" for="networkAccesses">Connection</label>
          <div class="col-auto form-right-input">
            <select v-model="gateway.networkAccess" id="networkAccesses" style="width: 225px" required>
              <NetworkConnections />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="imei">IMEI</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="imei"
              v-model="gateway.imei"
              name="imei"
              style="width:180px;"
              placeholder="00000000000000"
              maxlength="28"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="iccid">ICCID</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="iccid"
              v-model="gateway.iccid"
              name="iccid"
              style="width:220px;"
              placeholder="00000000000000"
              maxlength="28"
            />
          </div>
        </div>

        <div class="row-auto abutting form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn">Update</button>
            <button class="btn btn-red" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' && inEditMode }">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError, 'add-border-top': inEditMode }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': inEditMode || message != '' }">
            <span class="subtle-info status-last-modified">{{ getSinceSecs(gateway.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': inEditMode || message != '' }">
              <a @click="inEditMode=!inEditMode">
                <EditIcon class="button-icon" />
              </a>
            </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseGatewayComponent from '@/components/fleet/BaseGatewayComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import PowerSourceOptions from '@/components/selectOptions/PowerSourceOptions.vue'
import NetworkConnections from '@/components/selectOptions/NetworkConnections.vue'

export default {
  name: 'gateway-info',
  extends: BaseGatewayComponent,
  data () {
    return {
      gatewayBeforeEdit: {},
      mfmodels: []
    }
  },
  methods: {
    getGateway (id) {
      FleetDataService.getGateway(id)
        .then(response => {
          for (var r of this.ranches) {
            if (r.id === response.data.ranch) {
              this.ranch = r
              break
            }
          }
          for (var m of this.manufacturers) {
            if (m.id === response.data.manufacturer) {
              this.manufacturer = m
              if (m.models) {
                this.mfmodels = m.models.split(',')
              }
              break
            }
          }
          for (var ns of this.networkServers) {
            if (ns.id === this.networkServer) {
              this.networkServer = ns
              break
            }
          }
          this.gateway = response.data
          this.gatewayBeforeEdit = Object.assign({}, this.gateway)
          this.$state.creationName = this.gateway.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Gateway')
        })
    },
    handleSubmit () {
      FleetDataService.updateGateway(this.gateway.id, this.gateway)
        .then(response => {
          this.isError = false
          this.message = 'Gateway Updated'
          this.setNavGateway(this.$route.params.id)
          this.getGateway(this.gateway.id)
          this.inEditMode = false
        })
        .catch(e => {
          this.isError = true
          this.message = 'Gateway Update Failed'
          console.log(e.response)
        })
    },
    handleCancel () {
      Object.assign(this.gateway, this.gatewayBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.setNavGateway(this.$route.params.id)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
    this.retrieveAllNetworkServers(this.retrieveAllRanches(this.retrieveAllManufacturers(this.getGateway(this.$route.params.id))))
  },
  components: { CloseIcon, EditIcon, GeneralStatusOptions, PowerSourceOptions, NetworkConnections }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
