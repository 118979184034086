<template>
      <span class="spinner">
        <a>
          <svg class="button-icon refresh-button" v-bind:class="{ 'refreshing': isLoading, 'refresh-error': isError }" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 364.02 294.97"><defs></defs><path id="Refresh_Icon" data-name="Refresh Icon" class="cls-1" d="M325.24,176.38h35.11l-46,69.05-46-69.05h34a115.11,115.11,0,0,0-189.26-76A11.51,11.51,0,1,1,98.05,82.88a137.69,137.69,0,0,1,89.68-33.09C260,49.79,319.36,105.63,325.24,176.38Zm-218.07,23h-34a115.11,115.11,0,0,0,189.26,76,11.5,11.5,0,1,1,14.94,17.48A137.71,137.71,0,0,1,187.73,326C115.48,326,56.1,270.16,50.21,199.4H15.1l46-69.05Z" transform="translate(-5.72 -40.41)"/></svg>
        </a>
      </span>
</template>

<script>
export default {
  data () {
    return {
      isLoading: this.spinOnCreation,
      isError: false,
      startTime: 0
    }
  },
  props: ['spinOnCreation'],
  methods: {
    stopSpinningASAP () {
      this.isLoading = false
    },
    startSpinning () {
      this.isLoading = true
      this.isError = false
      this.startTime = Date.now()
    },
    stopSpinning () {
      var timeout = 500 - ((Date.now() - this.startTime) % 500)
      if (timeout > 12) {
        timeout -= 12
      }
      setTimeout(this.stopSpinningASAP, timeout)
    },
    setError () {
      this.isError = true
    }
  }
}
</script>
