
import { Options, Vue } from 'vue-class-component'
import GatewayMainList from '@/components/fleet/GatewayMainList.vue' // @ is an alias to /src
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    GatewayMainList,
    TabBar
  }
})
export default class GatewaysList extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Fleet', '')
  }
}
