import http from '@/http-common'
import qs from 'qs'

class FleetDataService {
  // Network Servers CRUD
  getAllNetworkServers () {
    return http.get('/networkServers')
  }

  getNetworkServer (id: string) {
    return http.get(`/networkServers/${id}`)
  }

  createNetworkServer (data: any) {
    return http.post('/networkServers', qs.stringify(data))
  }

  updateNetworkServer (id: string, data: any) {
    return http.put(`/networkServers/${id}`, qs.stringify(data))
  }

  // Manufacturers CRUD
  getAllManufacturers () {
    return http.get('/manufacturers')
  }

  getManufacturer (id: string) {
    return http.get(`/manufacturers/${id}`)
  }

  createManufacturer (data: any) {
    return http.post('/manufacturers', qs.stringify(data))
  }

  updateManufacturer (id: string, data: any) {
    return http.put(`/manufacturers/${id}`, qs.stringify(data))
  }

  // Gateways CRUD
  getAllGateways () {
    return http.get('/gateways')
  }

  getGatewaysByRanch (ranchName: string) {
    return http.get(`/ranches/${ranchName}/gateways`)
  }

  getGateway (id: string) {
    return http.get(`/gateways/${id}`)
  }

  createGateway (data: any) {
    return http.post('/gateways', qs.stringify(data))
  }

  updateGateway (id: string, data: any) {
    return http.put(`/gateways/${id}`, qs.stringify(data))
  }
}

export default new FleetDataService()
