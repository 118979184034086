
import { Options, Vue } from 'vue-class-component'
import NetworkServerAddForm from '@/components/fleet/NetworkServerAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    NetworkServerAddForm,
    TabBar
  }
})
export default class AddNetworkServer extends Vue {}
