<template>
  <TabBar activeTabName="templates" />
  <div class="row justify-content-center abutting">
    <TemplateAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import TemplateAddForm from '@/components/notifications/TemplateAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    TemplateAddForm,
    TabBar
  }
})
export default class AddTemplate extends Vue {}
</script>
