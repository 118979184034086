<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Threshold</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form threshold-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="id">Name</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:210px;"
            placeholder="threshold.name"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115" for="description">Description</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="description"
            v-model="description"
            name="description"
            style="width:210px;"
            placeholder="Short &amp; Human-Readable"
            maxlength="72"
          />
        </div>
      </div>

      <div id="v-model-enabled" class="row form-group">
        <label class="col-form-label form-left-header width-115" for="enabled">Monitoring</label>
        <div class="col-auto form-right-input">
          <input type="radio" class="margin-right-5" value="1" v-model="enabled" id="enabled" checked />Enabled
          &nbsp;&nbsp;&nbsp;
          <input type="radio" class="margin-right-5" value="0" v-model="enabled" id="disabled" />Disabled
        </div>
      </div>

      <div id="v-model-categories" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="categories">Category</label>
        <div class="col-auto form-right-input">
          <select v-model="category" id="categories" style="width: 210px" required>
            <NotificationCategories />
          </select>
        </div>
      </div>

      <div id="v-model-scopes" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="scopes">Scope:</label>
        <div class="col-auto form-right-input">
          <select v-model="scope" id="scope" style="width: 210px" required>
            <NotificationScopes />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115" for="filter">Filter</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="filter"
            v-model="filter"
            name="filter"
            style="width:210px;"
            placeholder="ranch-or-device-name-etc"
            maxlength="72"
          />
        </div>
      </div>

      <div class="row form-group d-none">
        <label class="col-form-label form-left-header width-115" for="attribute">Attribute</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="attribute"
            v-model="attribute"
            name="attribute"
            style="width:210px;"
            placeholder="Optional/Experimental"
            maxlength="72"
          />
        </div>
      </div>

      <hr />
      <div class="form-section-heading">Condition</div>

      <div id="v-model-sources" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="sources">Source</label>
        <div class="col-auto form-right-input">
          <select v-model="source" id="sources" style="width: 210px" required>
            <NotificationSources />
          </select>
        </div>
      </div>

      <div id="v-model-metrics" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="metrics">Metric</label>
        <div class="col-auto form-right-input">
          <select v-model="metric" id="metrics" style="width: 210px" required>
            <NotificationMetricsTelemetry />
          </select>
        </div>
      </div>

      <div id="v-model-operators" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="operators">Operator</label>
        <div class="col-auto form-right-input">
          <select v-model="operator" id="operators" style="width: 210px" required>
            <ThresholdOperators />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="attribute">Value</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="startVal"
            v-model="startVal"
            name="startVal"
            style="width:210px;"
            placeholder="3.14159"
            maxlength="32"
          />
          <p v-if="operator === 'btw'">to</p>
          <input
            v-if="operator === 'btw'"
            type="text"
            id="endVal"
            v-model="endVal"
            name="endVal"
            style="width:210px;"
            placeholder="3.14159"
            maxlength="32"
          />
        </div>
      </div>

      <div id="v-model-comparators" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="comparators">Compare To</label>
        <div class="col-auto form-right-input">
          <select v-model="compareTo" id="comparators" style="width: 210px" required>
            <ThresholdComparators />
          </select>
        </div>
      </div>

      <hr />
      <div class="form-section-heading">Triggering</div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-155" for="triggerTrendSecs">Minimum Trend</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="triggerTrendSecs"
            v-model="triggerTrendSecs"
            name="triggerTrendSecs"
            style="width:100px;"
            placeholder="0"
            maxlength="72"
          /> seconds
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115" for="triggerTemplate">Notif. Template</label>
        <div class="col-auto form-right-input">
          <select v-model="triggerTemplate" id="triggerTemplate" style="width: 210px">
            <option v-for="tmpl in templates" :value="tmpl.id" :key="tmpl.id">
              {{ tmpl.id }}
            </option>
          </select>
        </div>
      </div>

      <hr />

      <div class="form-section-heading">Resolution</div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-155" for="triggerTrendSecs">Minimum Trend</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="resolveTrendSecs"
            v-model="resolveTrendSecs"
            name="resolveTrendSecs"
            style="width:100px;"
            placeholder="0"
            maxlength="72"
          /> seconds
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115" for="resolveTemplate">Notif. Template</label>
        <div class="col-auto form-right-input">
          <select v-model="resolveTemplate" id="resolveTemplate" style="width: 210px">
            <option v-for="tmpl in templates" :value="tmpl.id" :key="tmpl.id">
              {{ tmpl.id }}
            </option>
          </select>
        </div>
      </div>

      <hr />

      <div class="form-section-heading">Flapping</div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-155" for="triggerTrendSecs">Minimum Trend</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="flapBufferSecs"
            v-model="flapBufferSecs"
            name="flapBufferSecs"
            style="width:100px;"
            placeholder="0"
            maxlength="72"
          /> seconds
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header width-55" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box width-270"
            placeholder="Enter any info about this Threshold that Agrology may find useful in the future."
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseThresholdComponent from '@/components/notifications/BaseThresholdComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import NotificationCategories from '@/components/selectOptions/NotificationCategories.vue'
import NotificationSources from '@/components/selectOptions/NotificationSources.vue'
import NotificationMetricsTelemetry from '@/components/selectOptions/NotificationMetricsTelemetry.vue'
import NotificationScopes from '@/components/selectOptions/NotificationScopes.vue'
import ThresholdOperators from '@/components/selectOptions/ThresholdOperators.vue'
import ThresholdComparators from '@/components/selectOptions/ThresholdComparators.vue'

export default {
  name: 'threshold-add',
  extends: BaseThresholdComponent,
  data () {
    return {
      id: '',
      description: '',
      enabled: 1,
      notes: '',
      category: '',
      scope: '',
      filter: '',
      source: '',
      metric: '',
      attribute: '',
      operator: '',
      compareTo: '',
      startVal: '',
      endVal: '',
      triggerTemplate: '',
      resolveTemplate: '',
      triggerTrendSecs: 0,
      resolveTrendSecs: 0,
      flapBufferSecs: 0
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        description: this.description,
        notes: this.notes,
        enabled: this.enabled,
        category: this.category,
        scope: this.scope,
        filter: this.filter,
        source: this.source,
        metric: this.metric,
        attribute: this.attribute,
        operator: this.operator,
        compareTo: this.compareTo,
        startVal: this.startVal,
        endVal: this.endVal,
        triggerTrendSecs: this.triggerTrendSecs,
        triggerTemplate: this.triggerTemplate,
        resolveTrendSecs: this.resolveTrendSecs,
        resolveTemplate: this.resolveTemplate,
        flapBufferSecs: this.flapBufferSecs
      }
      NotificationsDataService.createThreshold(data)
        .then(response => {
          this.isError = false
          this.message = ''
          this.$state.crudMessage = 'Threshold Created Successfully'
          this.$router.push({ name: 'threshold-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          if (e.resposne && e.response.data && e.response.data.message &&
            e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
            this.message = 'Threshold Already Exists'
            return
          }
          this.message = 'Threshold Creation Failed'
          console.log(e.response)
        })
    }
  },
  mounted () {
    this.retrieveAllTemplates()
    this.setNav('Notifications', 'Add Threshold')
  },
  components: { CloseIcon, NotificationCategories, NotificationSources, NotificationMetricsTelemetry, NotificationScopes, ThresholdOperators, ThresholdComparators }
}
</script>

<style>
</style>
