<template>
  <div class="col-lg-auto abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Gateways at this Ranch
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'gateway-add' }" title="Add New Gateway"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Device List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th>Gateway ID</th>
            <th><ClockIcon class="svg-icon-label"/></th>
            <th>Manu</th>
            <th class="d-none d-sm-block">Model</th>
            <th>Pwr</th>
            <th>Net</th>
            <th>Conn</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!activeGateways || activeGateways.length === 0" class="data-row-last">
            <td class="subtle-info">
              No Gateways Listed
            </td>
          </tr>
          <tr v-for="(gateway,i) of activeGateways" :key="gateway.id" v-bind:class="{'data-row-last': i+1 == activeGateways.length}">
            <td>
              <router-link :to="{  name: 'gateway-details', params: { id: gateway.id } }"
                v-bind:class="{ 'inactive': gateway.status === 'inactive' }"
              >
                {{ gateway.id }}
              </router-link>
            </td>
            <td>
              {{ getSince(gateway.report_time) }}
            </td>
            <td>
              {{ gateway.manufacturer }}
            </td>
            <td class="d-none d-sm-block">
              {{ gateway.model }}
            </td>
            <td>
              {{ gateway.powerSource }}
            </td>
            <td>
              {{ gateway.networkServer }}
            </td>
            <td>
              {{ gateway.networkAccess }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import Spinner from '@/components/Spinner.vue'
import ClockIcon from '@/components/svg/Clock'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'gateways-by-ranch-list',
  extends: BaseDeviceComponent,
  methods: {
    retrieveGateways (ranch) {
      this.$refs.spinner.startSpinning()
      FleetDataService.getGatewaysByRanch(ranch)
        .then(response => {
          this.gateways = response.data
          this.$refs.spinner.stopSpinning()
        })
        .catch(e => {
          console.log(e)
          this.$refs.spinner.setError()
          this.$refs.spinner.stopSpinning()
        })
    },
    refreshList () {
      this.retrieveGateways(this.$route.params.id)
    }
  },
  mounted () {
    this.retrieveGateways(this.$route.params.id)
  },
  components: { Spinner, ClockIcon, AddIcon }
}
</script>
