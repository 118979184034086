
import { Options, Vue } from 'vue-class-component'
import ThresholdMainList from '@/components/notifications/ThresholdMainList.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ThresholdMainList,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
