<template>
  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        Recent Alerts
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <div class="btn-group" role="group" aria-label="Alert List Filter">
          <input type="radio" class="btn-check" value="recent" name="listFilter" id="listFilter-all" v-model="listFilter" autocomplete="off" />
          <label class="btn btn-outline-primary" for="listFilter-all">&nbsp;All&nbsp;</label>

          <input type="radio" class="btn-check" value="active" name="listFilter" id="listFilter-active" v-model="listFilter" autocomplete="off">
          <label class="btn btn-outline-primary" for="listFilter-active">Active</label>

          <input type="radio" class="btn-check" value="resolved" name="listFilter" id="listFilter-resolved" v-model="listFilter" autocomplete="off">
          <label class="btn btn-outline-primary" for="listFilter-resolved">Resolved</label>
        </div>
        <Spinner title="Refresh Alerts List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <div class="data-table">
      <table width="100%">
        <thead>
          <tr class="data-table-header">
            <th style="width:10px !important;">Alert ID</th>
            <th style="width:10px !important;"><ClockIcon class="svg-icon-label"/></th>
            <th style="width:10px !important;">Status</th>
            <th style="width:10px !important;">Category</th>
            <th style="width:10px !important;">Ranch</th>
            <th style="width:10px !important;">Scope</th>
            <th style="width:10px !important;">Threshold</th>
            <th style="width:10px !important;">Metric</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!alertList || alertList.length === 0">
            <td class="subtle-info">
              No Alerts Listed
            </td>
          </tr>
          <tr v-for="(alert,i) of alertList" :key="alert.id" v-bind:class="{'data-row-last': i+1 == alertList.length}">
            <td>
              <router-link :to="{  name: 'alert-details', params: { id: alert.id } }"
                v-bind:class="{ 'inactive': alert.status === 'inactive' }"
              >
                {{ getLastWord(alert.id) }}
              </router-link>
            </td>
            <td>
              {{ getSinceSecs(alert.lastModified) }}
            </td>
            <td style="padding-right:6px" v-bind:class="{'notable': alert.status === 'active', 'subtle-info': alert.status === 'resolved'}">
              {{ alert.status }}
              <span v-if="alert.locked"><LockIcon /></span>
            </td>
            <td style="padding-right:6px">
              {{ alert.category }}
            </td>
            <td style="padding-right:6px">
              {{ alert.ranch }}
            </td>
            <td style="padding-right:6px">
              {{ alert.scope }}
            </td>
            <td style="padding-right:6px">
              {{ alert.threshold }}
            </td>
            <td>
              {{ alert.metric }}
            </td>
            <td>
              {{ alert.detail }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseAlertComponent from '@/components/notifications/BaseAlertComponent.vue'
import Spinner from '@/components/Spinner.vue'
import ClockIcon from '@/components/svg/Clock'
import LockIcon from '@/components/svg/Lock'

export default {
  name: 'alerts-recent-list',
  extends: BaseAlertComponent,
  data () {
    return {
      listFilter: 'recent'
    }
  },
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveRecentAlerts(this.$refs.spinner.stopSpinning)
    }
  },
  computed: {
    alertList () {
      if (this.listFilter === 'active') {
        return this.activeAlerts
      } else if (this.listFilter === 'resolved') {
        return this.resolvedAlerts
      }
      return this.alerts
    }
  },
  mounted () {
    this.setNavSub('alerts')
    this.refreshList()
  },
  components: { Spinner, ClockIcon, LockIcon }
}
</script>
