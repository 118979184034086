<template>
  <div v-if="majorError">
    <h5 class='app-failure'>{{ majorError }}</h5>
  </div>
  <div v-else-if="apnSubscription" class="col-auto component-pane component-pane-narrow">
    <h5><span class="node-label">APN Subscription</span> {{ getLast10(apnSubscription.deviceToken) }}</h5>
    <!-- DISPLAY MODE BELOW -->
    <div class="data-table">
      <div class="container abutting">

        <div class="row align-content-center" v-if="apnSubscription.deviceToken">
          <div class="col">
            <div class="form-section-heading">Device Token</div>
            <span style="font-size:9px;padding-left:10px;">
              {{ apnSubscription.deviceToken }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="apnSubscription.bundleID">
          <div class="col">
            <div class="form-section-heading">BundleID / Topic</div>
            <span style="padding-left:10px;">
              {{ apnSubscription.bundleID }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="apnSubscription.email">
          <div class="col">
            <div class="form-section-heading">User Email</div>
            <span style="padding-left:10px;">
              {{ apnSubscription.email }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="apnSubscription.ranches">
          <div class="col">
            <div class="form-section-heading">Ranches</div>
            <span style="padding-left:10px;">
              {{ apnSubscription.ranches }}
            </span>
          </div>
        </div>

        <div class="row align-content-center" v-if="apnSubscription.deviceType">
          <div class="col">
            <div class="form-section-heading">Device Type</div>
            <span style="padding-left:10px;">
              {{ apnSubscription.deviceType }}
            </span>
          </div>
        </div>

      </div>

      <div class="container abutting">
        <div class="row d-flex align-items-center no-border-bottom response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError }"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col-auto abutting" v-bind:class="{ 'd-none': message != '' }">
            <span class="subtle-info">Updated: {{ getSinceSecs(apnSubscription.lastModified) }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message != '' }">
              <a class="btn btn-red" style="margin-left: 20px" @click.prevent="deleteSubscription()">Delete</a>
          </span>
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-top-10">
      <h5 class="node-label">Send Test Message</h5>

      <div class="data-table">
        <div class="container abutting">
          <div class="row align-content-center no-border-bottom">
            <div class="col">
              <div class="form-section-heading">Notification Template</div>
            </div>
          </div>
          <div class="row align-content-center no-border-bottom margin-left-10">
            <div class="col">
              <select v-model="testNotificationTemplate" style="width: 210px">
                <option v-for="tmpl in pushTemplates" :value="tmpl.id" :key="tmpl.id">
                  {{ tmpl.id }}
                </option>
              </select>
            </div>
            <div class="col d-flex justify-content-end" style="max-height: 31px">
              <a class="btn btn-red" style="margin-left: 20px" @click.prevent="sendNotification()">Send</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import BaseSubscriptionComponent from '@/components/notifications/BaseSubscriptionComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'

export default {
  name: 'apnSubscription',
  extends: BaseSubscriptionComponent,
  data () {
    return {
      testNotificationTemplate: ''
    }
  },
  methods: {
    sendNotification () {
      if (!this.apnSubscription || !this.testNotificationTemplate) {
        return
      }
      var data = {
        deviceToken: this.apnSubscription.deviceToken,
        bundleID: this.apnSubscription.bundleID,
        template: this.testNotificationTemplate
      }
      if (confirm('This will send a real notification to a real device. Are you sure?')) {
        NotificationsDataService.sendTestNotification(data)
          .then(response => {
            this.isError = false
            this.message = 'Notification sent.'
          })
          .catch(e => {
            this.isError = true
            this.message = 'Notification failed to send.'
            console.log(e.response)
          })
      }
    },
    deleteSubscription () {
      if (!this.apnSubscription) {
        return
      }
      if (confirm('This will permanently unsubscribe this device from receiving notifications. Are you sure?')) {
        NotificationsDataService.deleteApnSubscriptionsByDeviceToken(this.apnSubscription.deviceToken, this.apnSubscription.bundleID)
          .then(response => {
            this.$router.push('/subscriptions')
          })
          .catch(e => {
            this.majorError = ''
            this.handleError(e, 'ApnSubscription')
          })
      }
    },
    getApnSubscription (deviceToken, bundleID) {
      NotificationsDataService.getApnSubscriptionsByDeviceToken(deviceToken, bundleID)
        .then(response => {
          this.apnSubscription = response.data
          this.apnSubscription.id = this.apnSubscription.deviceToken + '.' + this.apnSubscription.bundleID
          this.$state.creationName = this.apnSubscription.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'ApnSubscription')
        })
    }
  },
  mounted () {
    this.getApnSubscription(this.$route.params.deviceToken, this.$route.params.bundleID)
    this.setNavSub(this.$route.params.deviceToken)
    if (this.$state.crudMessage) {
      this.message = this.$state.crudMessage
      this.$state.crudMessage = ''
    }
    this.retrieveAllTemplates()
  },
  components: { CloseIcon }
}
</script>

<style>
  .btn-red {
    margin-left: 20px;
  }
</style>
