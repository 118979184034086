<template>
  <TabBar activeTabName="thresholds" />
  <div class="row justify-content-center abutting">
    <ThresholdAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ThresholdAddForm from '@/components/notifications/ThresholdAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ThresholdAddForm,
    TabBar
  }
})
export default class AddThreshold extends Vue {}
</script>
