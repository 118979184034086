<template>
    <option value="airHumidity">airHumidity</option>
    <option value="airPressure">airPressure</option>
    <option value="airTemp">airTemp</option>
    <option value="battery">battery</option>
    <option value="irAmbientTemp">irAmbientTemp</option>
    <option value="irObjectTemp">irObjectTemp</option>
    <option value="irrigationCount">irrigationCount</option>
    <option value="irrigationDuration">irrigationDuration</option>
    <option value="soilMoisture">soilMoisture</option>
    <option value="soilTemperature">soilTemperature</option>
    <option value="soilTension">soilTension</option>
    <option value="soilWaterPotential">soilWaterPotential</option>
</template>
