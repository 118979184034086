import http from '@/http-common'
import qs from 'qs'

class RanchDataService {
  getAll () {
    return http.get('/ranches')
  }

  get (id: string) {
    return http.get(`/ranches/${id}`)
  }

  create (data: any) {
    return http.post('/ranches', qs.stringify(data))
  }

  update (id: any, data: any) {
    return http.put(`/ranches/${id}`, qs.stringify(data))
  }

  getAllByCustomer (customerName: string) {
    return http.get(`/customers/${customerName}/ranches`)
  }
}

export default new RanchDataService()
