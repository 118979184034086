<template>
  <div class="col-auto component-pane abutting">
    <h5>Add Gateway</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form gateway-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">GW ID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="gateway-name"
            maxlength="28"
          />
        </div>
      </div>

      <div id="v-model-ranches" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="ranches">Ranch</label>
        <div class="col-auto form-right-input">
          <select v-model="ranchName" id="ranches" style="width: 225px" required>
            <option v-for="ranch in activeRanches" :value="ranch.id" :key="ranch.id">
              {{ ranch.id }}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Gateway that Agrology may find useful in the future."
          />
        </div>
      </div>

      <hr />

      <div id="v-model-manufacturers" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="manufacturers">Manuf:</label>
        <div class="col-auto form-right-input">
          <select v-model="manufacturer" id="manufacturers" style="width: 225px" required>
            <option v-for="manufacturer in activeManufacturers" :value="manufacturer.id" :key="manufacturer.id">
              {{ manufacturer.id }}
            </option>
          </select>
        </div>
      </div>

      <div id="v-model-mfmodels" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="mfmodels">Model</label>
        <div class="col-auto form-right-input">
          <select v-model="model" id="mfmodels" style="width: 225px" required>
          </select>
        </div>
      </div>

      <div id="v-model-powers" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="powers">Power</label>
        <div class="col-auto form-right-input">
          <select v-model="powerSource" id="powers" style="width: 225px" required>
            <PowerSourceOptions />
          </select>
        </div>
      </div>

      <hr />

      <div id="v-model-networkServers" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="networkServers">Network:</label>
        <div class="col-auto form-right-input">
          <select v-model="networkServer" id="networkServers" style="width: 225px" required>
            <option v-for="networkServer in activeNetworkServers" :value="networkServer.id" :key="networkServer.id">
              {{ networkServer.id }}
            </option>
          </select>
        </div>
      </div>

      <div id="v-model-networkAccesses" class="row form-group">
        <label class="col-form-label form-left-header label-required" for="networkAccesses">Connection</label>
        <div class="col-auto form-right-input">
          <select v-model="networkAccess" id="networkAccesses" style="width: 225px" required>
            <NetworkConnections />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="imei">IMEI</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="imei"
            v-model="imei"
            name="imei"
            style="width:180px;"
            placeholder="00000000000000"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="iccid">ICCID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="iccid"
            v-model="iccid"
            name="iccid"
            style="width:220px;"
            placeholder="00000000000000"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row-auto abutting form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn">Submit</button>
          <a class="btn btn-red" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

      <div class="container abutting"
        v-bind:class="{ 'd-none': message == '' }">
        <div class="row d-flex align-items-center no-border-bottom add-border-top response-row"
          v-bind:class="{'data-row-ok': message && !isError, 'data-row-warning': message && isError}"
        >
          <div class="col-auto status-box" v-if="message">
            <span class="status-ok" v-if="!isError">✓</span>
            <span class="status-error" v-if="isError">❌</span>
            <span class="status-ok status-message-right" v-bind:class="{ 'status-error': isError }">{{ this.message }}</span>
          </div>
          <div class="col d-flex justify-content-end">
            <span v-bind:class="{ 'd-none': message == '' }">
              <a @click="message='';isError=false">
                <CloseIcon class="button-icon" v-bind:class="{ 'status-error': isError }" />
              </a>
            </span>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseGatewayComponent from '@/components/fleet/BaseGatewayComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import PowerSourceOptions from '@/components/selectOptions/PowerSourceOptions.vue'
import NetworkConnections from '@/components/selectOptions/NetworkConnections.vue'

export default {
  name: 'gateway-add',
  extends: BaseGatewayComponent,
  data () {
    return {
      id: '',
      status: 'active',
      notes: '',
      ranch: '',
      powerSource: '',
      manufacturer: '',
      model: '',
      imei: '',
      iccid: '',
      networkAccess: '',
      networkServer: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        status: this.status,
        notes: this.notes,
        ranch: this.ranch,
        powerSource: this.powerSource,
        manufacturer: this.manufacturer,
        model: this.model,
        imei: this.imei,
        iccid: this.iccid,
        networkAccess: this.networkAccess,
        networkServer: this.networkServer
      }
      FleetDataService.createGateway(data)
        .then(response => {
          this.isError = false
          this.message = ''
          this.$state.crudMessage = 'Gateway Created Successfully'
          this.$router.push({ name: 'gateway-details', params: { id: this.id } })
        })
        .catch(e => {
          this.isError = true
          if (e.resposne && e.response.data && e.response.data.message &&
            e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
            this.message = 'Gateway Already Exists'
            return
          }
          this.message = 'Gateway Creation Failed'
          console.log(e.response)
        })
    }
  },
  mounted () {
    this.retrieveAllNetworkServers(this.retrieveAllRanches(this.retrieveAllManufacturers()))
    if (this.$state.creationName) {
      this.ranchName = this.$state.creationName
    }
  },
  created () {
    this.setNav('Fleet', 'Add Gateway')
  },
  components: { CloseIcon, GeneralStatusOptions, PowerSourceOptions, NetworkConnections }
}
</script>

<style>
</style>
