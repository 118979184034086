<template>
  <TabBar activeTabName="customers" />
  <div class="row justify-content-center abutting">
    <CustomerAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomerAddForm from '@/components/fleet/CustomerAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    CustomerAddForm,
    TabBar
  }
})
export default class AddCustomer extends Vue {}
</script>
