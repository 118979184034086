<template>
  <TabBar activeTabName="devices" />
  <div class="row justify-content-center abutting">
    <DeviceAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import DeviceAddForm from '@/components/fleet/DeviceAddForm.vue' // @ is an alias to /src
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    DeviceAddForm,
    TabBar
  }
})
export default class AddDevice extends Vue {}
</script>
