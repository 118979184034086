<template>

  <div class="col component-pane abutting">
    <div class="d-flex justify-content-between align-items-center">
      <h5>
        All Customers
      </h5>
      <div class="d-flex justify-content-end align-items-center padding-bottom-5">
        <router-link :to="{  name: 'customer-add' }" title="Add New Customer"><AddIcon class="button-icon" /></router-link>
        <Spinner title="Refresh Customers List" :spinOnCreation="true" @click="refreshList()" ref="spinner" class="margin-left-10 margin-right-10"/>
      </div>
    </div>
    <ul class="list-group">
      <li v-if="!activeCustomers || activeCustomers.length === 0" class="list-group-item subtle-info">
          No Customers Listed
      </li>
      <li class="list-group-item" v-for="customer in activeCustomers" :key="customer.id">
        <router-link :to="{  name: 'customer-details', params: { id: customer.id } }"
          v-bind:class="{ 'inactive': customer.status === 'inactive' }"
        >
          {{ customer.id }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseCustomerComponent from '@/components/fleet/BaseCustomerComponent.vue'
import Spinner from '@/components/Spinner.vue'
import AddIcon from '@/components/svg/AddIcon.vue'

export default {
  name: 'customers-list',
  extends: BaseCustomerComponent,
  methods: {
    refreshList () {
      this.$refs.spinner.startSpinning()
      this.retrieveAllCustomers(this.$refs.spinner.stopSpinning)
    }
  },
  mounted () {
    this.refreshList()
  },
  components: { Spinner, AddIcon }
}
</script>
